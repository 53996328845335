import { useState, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import Icon, { IconsKey } from '@base/Icon';
import DropdownSelect, { DropdownOption } from '@base/Dropdown/DropdownSelect';
import Tooltip from '@base/Tooltip';
import {IS_MOBILE} from '@helpers/browsers';

import './Dropdown.css';

type Props<T> = {
  className?: string;
  label: string;
  selectedValue: T;
  options: DropdownOption<T>[];
  onChange: (value: T) => void;
  tooltipContent: string | ReactNode;
  selectId?: string;
};

function Dropdown<T extends IconsKey>({
  className = '',
  label,
  selectedValue,
  onChange,
  options,
  tooltipContent,
  selectId,
}: Props<T>): ReactElement {
  const [focused, setFocused] = useState(false);
  const classes = classnames('Dropdown', className, {
    'Dropdown--focused': focused,
    'Dropdown--mobile': IS_MOBILE,
  });

  return (
    <div className={classes}>
      <div className="Dropdown-LabelContainer">
        <span className="Dropdown-Label">{label}</span>
        {tooltipContent && (
          <Tooltip
            contextClassName={'Dropdown-TooltipContext'}
            context={<Icon className="Dropdown-InfoIcon" name="info" />}
            content={tooltipContent}
            position="above"
            distance={9}
          />
        )}
        <span className="Dropdown-Separator">|</span>
      </div>
      <DropdownSelect
        selectedValue={selectedValue}
        options={options}
        onClick={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        selectId={selectId}
        iconValue={selectedValue as IconsKey}
      />
    </div>
  );
}

export default Dropdown;

import { 
  useState, 
  useRef, 
  useEffect, 
  useLayoutEffect, 
  ReactElement, 
  FocusEventHandler, 
  KeyboardEventHandler,
  CSSProperties,
} from 'react';
import ReactDOM from 'react-dom';
import Icon, { IconsKey } from '@base/Icon';
import {IS_MOBILE} from '@helpers/browsers';

import './DropdownSelect.css';

export type DropdownOption<T> = {
  value: T;
  label: string;
};

type Props<T> = {
  selectedValue: T;
  options: DropdownOption<T>[];
  onClick: (value: T) => void;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  contextClassName?: string;
  container?: HTMLElement;
  distance?: number;
  selectId?: string;
  iconValue?: IconsKey | null;
};

function DropdownSelect<T extends string>({
  selectedValue,
  options,
  onClick,
  onFocus = () => {},
  onBlur = () => {},
  contextClassName = '',
  container = document.body,
  distance = 8,
  selectId = 'Dropdown-Select',
  iconValue = null,
}: Props<T>): ReactElement {
  const selectedOption = options.filter(
    ({value}) => selectedValue === value
  )[0];
  const [show, toggleShow] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [contextPositioning, setContextPosition] = useState<DOMRect>(
    {} as DOMRect
  );
  const contextReference = useRef<HTMLDivElement>(null);

  // Calculate position
  useEffect(() => {
    if (contextReference.current) {
      setContextPosition(contextReference.current.getBoundingClientRect());
    }
  }, [show]);

  // Update position based on DOM mutation
  useLayoutEffect(() => {
    const updateContextPosition = () => {
      if (contextReference.current) {
        setContextPosition(contextReference.current.getBoundingClientRect());
      }
    };
    const closeDropdown = () => toggleShow(false);

    window.addEventListener('resize', updateContextPosition);
    window.addEventListener('scroll', updateContextPosition);
    updateContextPosition();
    document.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('resize', updateContextPosition);
      window.removeEventListener('scroll', updateContextPosition);
      document.removeEventListener('click', closeDropdown);
    };
  }, [show]);

  const contentPositionStyles: CSSProperties = contextPositioning
    ? {
        position: 'absolute',
        top:
          contextPositioning.top +
          contextPositioning.height +
          window.scrollY +
          distance,
        left: contextPositioning.left - 7,
        zIndex: 1000,
        perspective: 2000,
      }
    : {};

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();

      // If showing, possibly select and close
      if (show) {
        if (highlightIndex > -1) {
          onClick(options[highlightIndex].value);
        }
        toggleShow(false);
      } else {
        toggleShow(true);
      }
    } else if (
      show &&
      event.keyCode === 40 &&
      highlightIndex < options.length - 1
    ) {
      // Down
      event.preventDefault();
      setHighlightIndex(highlightIndex + 1);
    } else if (show && event.keyCode === 38 && highlightIndex > 0) {
      // Up
      event.preventDefault();
      setHighlightIndex(highlightIndex - 1);
    } else if (show && event.keyCode === 9) {
      // Do this manually instead of on blur because we dont want to fight the window click listener
      toggleShow(false);
    }
  };

  return (
    <>
      <div
        tabIndex={0}
        ref={contextReference}
        className={`DropdownSelect-Trigger ${contextClassName}`}
        onClick={() => toggleShow(!show)}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {IS_MOBILE && (
          <select
            className="DropdownSelect-Native"
            id={selectId}
            value={selectedValue}
            onChange={(e) => onClick(e.target.value as T)}
          >
            {options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        )}
        <div className="DropdownSelect-Label">
          <div className="DropdownSelect-LabelLeft">
            {iconValue ? (
              <Icon
                className="Dropdown-OptionIcon"
                name={selectedOption.value as IconsKey}
              />
            ) : null}
            {selectedOption.label}
          </div>
          <div className="DropdownSelect-LabelRight">
            <Icon className="Dropdown-ChevronIcon" name="dropdownChevron" />
          </div>
        </div>
      </div>

      {!IS_MOBILE &&
        ReactDOM.createPortal(
          <div style={contentPositionStyles}>
            <div
              className={`DropdownSelect-List ${
                show
                  ? 'DropdownSelect-List--show'
                  : 'DropdownSelect-List--hidden'
              }`}
            >
              {options.map(({label: optionLabel, value}, index) => (
                <div
                  key={value}
                  className={`DropdownSelect-ListItem
                  ${
                    value === selectedOption.value
                      ? 'Dropdown-Select-ListItem--selected'
                      : ''
                  }
                ${
                  index === highlightIndex
                    ? 'DropdownSelect-ListItem--highlighted'
                    : ''
                }`}
                  onClick={() => onClick(value)}
                >
                  {iconValue ? (
                    <div className="DropdownSelect-LabelLeft">
                      <Icon
                        className="DropdownSelect-ListItemIcon"
                        name={value as IconsKey}
                      />
                    </div>
                  ) : null}
                  {optionLabel}
                </div>
              ))}
            </div>
          </div>,
          container
        )}
    </>
  );
}

export default DropdownSelect;

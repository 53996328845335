import { ReactElement, ReactNode, CSSProperties } from 'react';

import { Fragment, useState, useRef, useEffect, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import Transition from 'react-transition-group/Transition';
import {IS_MOBILE, IS_SAFARI} from '@helpers/browsers';

import Icon from '@base/Icon';

import './Tooltip.css';

export const DEFAULT_ENTER_TIME = 250;
export const DEFAULT_EXIT_TIME = 200;
/* Keep in sync with .Tooltip-Text's width in Tooltip.css */
const TOOLTIP_WIDTH = 220;
/* Keep in sync with .Tooltip-Text's padding in Tooltip.css */
const TOOLTIP_PADDING = 12;
const MOBILE_TOOLTIP_HEIGHT = 80;
const MINUMUM_TOOLTIP_LEFT = 5;

export type TooltipPositions = 'above';

type Props = {
  context: ReactNode;
  content: string | ReactNode;
  contextClassName?: string;
  container?: HTMLElement;
  distance?: number;
  position?: TooltipPositions;
  allowPointerEvents? : boolean;
}

const Tooltip = ({
  context,
  content,
  contextClassName = '',
  container = document.body,
  distance = 4,
  position = 'above',
  allowPointerEvents,
}: Props): ReactElement => {
  const isString = typeof content === 'string';
  const [show, toggleShow] = useState(false);
  const [contextPositioning, setContextPosition] = useState<DOMRect>({} as DOMRect);
  const contextReference = useRef<HTMLDivElement>(null);

  // Calculate position
  useEffect(() => {
    if (contextReference.current) {
      setContextPosition(contextReference.current.getBoundingClientRect());
    }
  }, [show]);

  useEffect(() => {
    const clear = () => {
      toggleShow(false);
    };

    window.addEventListener('mousedown', clear);

    return () => {
      window.removeEventListener('mousedown', clear);
    };
  });

  // Update position based on DOM mutation
  useLayoutEffect(() => {
    const updateContextPosition = () => {
      if (contextReference.current) {
        setContextPosition(contextReference.current.getBoundingClientRect());
      }
    };

    window.addEventListener('resize', updateContextPosition);
    updateContextPosition();
    return () => window.removeEventListener('resize', updateContextPosition);
  }, [show]);

  let contentPositionStyles: CSSProperties = {
    width: TOOLTIP_WIDTH,
    position: 'absolute',
    zIndex: 1000,
    pointerEvents: allowPointerEvents ? undefined : 'none',
  };
  if (contextPositioning) {
    // If our contextPositioning is too close to the left or right edge then
    // shrink the tooltip width
    if (contextPositioning.left + contextPositioning.width / 2 - TOOLTIP_PADDING < TOOLTIP_WIDTH / 2) {
      contentPositionStyles.width = (
        contextPositioning.left + 
        contextPositioning.width / 2) * 2  - TOOLTIP_PADDING;
    } else if (window.innerWidth - contextPositioning.right + contextPositioning.width / 2 - TOOLTIP_PADDING < TOOLTIP_WIDTH / 2) {
      contentPositionStyles.width = (
        window.innerWidth - 
        contextPositioning.right + 
        contextPositioning.width / 2) * 2 - TOOLTIP_PADDING;
    }
    
    switch (position) {
      case 'above':
      default:
        contentPositionStyles = {
          ...contentPositionStyles,
          bottom: 
            window.innerHeight - 
            contextPositioning.y -
            window.scrollY +
            distance,
          left: Math.max(
            MINUMUM_TOOLTIP_LEFT,
            contextPositioning.left + (contextPositioning.width / 2)
                - (contentPositionStyles.width as number) / 2,
          ),          
        };
    }
  }

  // Mobile tooltips portal to a bottom bar on tap
  if (IS_MOBILE) {
    contentPositionStyles = {
      position: 'fixed',
      top: window.innerHeight - MOBILE_TOOLTIP_HEIGHT,
      left: 0,
      right: 0,
    };

    const scrimClasses = classnames('Tooltip-MobileScrim', {
      'Tooltip-MobileScrim--hidden': !show,
      'Tooltip-MobileScrim--safari': IS_MOBILE && IS_SAFARI,
    });

    return (
      <Fragment>
        <div
          ref={contextReference}
          className={`Tooltip-Context ${contextClassName}`}
          onClick={() => toggleShow(!show)}
        >
          {context}
        </div>
        {ReactDOM.createPortal(
          <div className={scrimClasses}>
            <div style={contentPositionStyles}>
              <Transition
                in={show}
                className="Tooltip-animateMobile"
                timeout={{enter: DEFAULT_ENTER_TIME, exit: DEFAULT_EXIT_TIME}}
                appear
                mountOnEnter
                unmountOnExit
              >
                {status => {
                  const transitionClassName = `Tooltip-animateMobile Tooltip-animateMobile-${status}`;

                  return (
                    <div
                      className={`Tooltip Tooltip--mobile ${transitionClassName}`}
                    >
                      <div className="Tooltip-Text">
                        {isString ? <p>{content}</p> : content}
                      </div>
                    </div>
                  );
                }}
              </Transition>
            </div>
          </div>,
          container,
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div
        ref={contextReference}
        className={`Tooltip-Context ${contextClassName}`}
        onMouseEnter={() => toggleShow(true)}
        onMouseLeave={allowPointerEvents ? undefined : () => toggleShow(false)}
      >
        {context}
      </div>
      {ReactDOM.createPortal(
        <div style={contentPositionStyles}>
          <Transition
            in={show}
            className="Tooltip-animate"
            timeout={{enter: DEFAULT_ENTER_TIME, exit: DEFAULT_EXIT_TIME}}
            appear
            mountOnEnter
            unmountOnExit
          >
            {status => {
              const transitionClassName = `Tooltip-animate Tooltip-animate-${status} Tooltip-animate--${position}`;

              return (
                <div className={`Tooltip ${transitionClassName}`}>
                  <div className="Tooltip-Text" onMouseLeave={() => toggleShow(false)}>
                    {isString ? <p>{content}</p> : content}
                    <Icon
                      className={`Tooltip-Arrow Tooltip-Arrow--${position}`}
                      name="tooltipArrow"
                      height="9px" // Keep this 1px more than the arrow alignment in CSS
                    />
                  </div>
                </div>
              );
            }}
          </Transition>
        </div>,
        container,
      )}
    </Fragment>
  );
};

export default Tooltip;

import { ReactElement } from 'react';

import Toggle, { ToggleProps } from './Toggle';

import './Toggle.css';

export type ButtonGroupOption<T> = {
  value: T;
  label: string;
};

type ButtonGroupProps<T> = ToggleProps & {
  onChange: (val: T) => void;
  selected: T;
  options?: ButtonGroupOption<T>[];
  disabled?: boolean;
};

function ButtonGroup<T extends string>(props: ButtonGroupProps<T>): ReactElement {
  const {
    selected,
    onChange,
    disabled,
    options = [],
    label,
    tooltipContent,
    className,
    tooltipDistance,
    disabledTooltipContent,
  } = props

  return (
    <Toggle
      type='buttonGroup'
      label={label}
      tooltipContent={tooltipContent}
      className={className}
      tooltipDistance={tooltipDistance}
      disabledTooltipContent={disabledTooltipContent}
      disabled={disabled}
    >
      <div 
        className={`ButtonGroup ${
          disabled ? 'ButtonGroup-Button--disabled': ''
        }`}
      >
        {options.map(option => (
          <button
            className={`ButtonGroup-Button ${
              selected === option.value ? 'ButtonGroup-Button--selected' : ''
            }`}
            onClick={() => onChange(option.value)}
            key={option.label}
            disabled={disabled}
          >
            {option.label}
          </button>
        ))}
      </div>
    </Toggle>
  );
}

export default ButtonGroup;
import {BillingPeriods, CheckoutModes, Wallets} from '@helpers/types';

export interface DemoUrlParams {
  mode: CheckoutModes;
  billingPeriod: BillingPeriods;
  hasCoupons: boolean;
  hasTaxes: boolean;
  /** If true, shows shipping address UI in checkout */
  hasBillingAndShipping: boolean;
  /** If true, shows shipping method selection UI in checkout */
  hasShippingRate: boolean;
  hasFreeTrial: boolean;
  hasPolicies: boolean;
  hasPhoneNumber: boolean;
  hasUpsells: boolean;
  wallet: Wallets;
}

export default function getDemoUrl(
  paramConfig: DemoUrlParams,
  location: string
) {
  const paramString = Object.entries(paramConfig).reduce(
    (str, [paramKey, paramValue]) => {
      return `${str}&${paramKey}=${encodeURIComponent(paramValue)}`;
    },
    `country=${location}`
  );

  return fetch(encodeURI(`/api/demo-session?${paramString}`))
    .then((res) => res.json())
    .then((res) => res.url);
}

import Bowser from 'bowser';

const browser = Bowser.parse(window.navigator.userAgent);
const IS_MOBILE =
  (browser.platform && browser.platform.type !== 'desktop') || false;

const IS_FIREFOX =
  (browser.browser && browser.browser.name === 'Firefox') || false;

const IS_SAFARI =
  (browser.browser && browser.browser.name === 'Safari') || false;

export {IS_MOBILE, IS_FIREFOX, IS_SAFARI};

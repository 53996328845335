import { ReactElement } from 'react';

import Icon from '@base/Icon';
import Tooltip from '@base/Tooltip';

import './Toggle.css';

type ToggleTypes = 'slider' | 'buttonGroup' | 'brandColor' | 'textDropdown';

export type ToggleProps = {
  label: string;
  type?: ToggleTypes;
  tooltipContent: string;
  className?: string;
  tooltipDistance?: number;
  disabled?: boolean;
  disabledTooltipContent?: string;
  children?: ReactElement
};

function Toggle({
  label,
  type = 'slider',
  tooltipContent,
  className = '',
  tooltipDistance = 8,
  disabled = false,
  disabledTooltipContent = '',
  children
}: ToggleProps): ReactElement {
  return (
    <div className={`Toggle Toggle--${type} ${className} ${
        disabled ? 'Toggle--disabled' : ''
    }`}>
      <label className="Toggle-Label">
        {label}
        {tooltipContent && (
          <Tooltip
            contextClassName={'Toggle-TooltipContext'}
            context={<Icon className="Toggle-InfoIcon" name="info" />}
            content={tooltipContent}
            position="above"
            // The icon has a top: -2px style to position it centered on the line so 
            // add 2 to the distance
            distance={2+tooltipDistance}
          />
        )}
      </label>
      {!disabled || !disabledTooltipContent ? children : (
        <Tooltip
          contextClassName={'Toggle-TooltipContext--disabled'}
          context={children}
          content={disabledTooltipContent}
          position="above"
          distance={tooltipDistance}
        />
      )}
    </div>
  );
}

export default Toggle;
import { SettingsState } from "@slices/settingsSlice";

export function saveConfig(config: Partial<SettingsState>) {
  const stringConfig = JSON.stringify(config);
  sessionStorage.setItem('config', stringConfig);
}

export function loadConfig() {
  const loadedConfig = sessionStorage.getItem('config');
  if (loadedConfig) {
    return JSON.parse(loadedConfig);
  }

  return {};
}

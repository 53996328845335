import {ReactElement} from 'react';

import InlineSVG from '@base/InlineSVG';

import flagIcons from '@icons/flags';
import {ReactComponent as singlePurchase} from '@icons/singlePurchase.svg';
import {ReactComponent as singlePurchaseGray} from '@icons/singlePurchaseGray.svg';
import {ReactComponent as subscription} from '@icons/subscription.svg';
import {ReactComponent as subscriptionGray} from '@icons/subscriptionGray.svg';
import {ReactComponent as chevronRight} from '@icons/chevronRight.svg';
import {ReactComponent as chevronRightWhite} from '@icons/chevronRightWhite.svg';
import {ReactComponent as stripe} from '@icons/stripe.svg';
import {ReactComponent as features} from '@icons/features.svg';
import {ReactComponent as mobile} from '@icons/mobile.svg';
import {ReactComponent as desktop} from '@icons/desktop.svg';
import {ReactComponent as info} from '@icons/info.svg';
import {ReactComponent as dropdownChevron} from '@icons/dropdownChevron.svg';
import {ReactComponent as applePay} from '@icons/applepay.svg';
import {ReactComponent as googlePay} from '@icons/googlepay.svg';
import {ReactComponent as lock} from '@icons/lock.svg';
import {ReactComponent as check} from '@icons/check.svg';
import {ReactComponent as plainCheck} from '@icons/plainCheck.svg';
import {ReactComponent as restart} from '@icons/restart.svg';
import {ReactComponent as tooltipArrow} from '@icons/tooltipArrow.svg';
import {ReactComponent as bank} from '@icons/bank.svg';
import {ReactComponent as cancel} from '@icons/cancel.svg';
import {ReactComponent as ideal} from '@icons/ideal.svg';
import {ReactComponent as boleto} from '@icons/boleto.svg';
import {ReactComponent as alipay} from '@icons/alipay.svg';
import {ReactComponent as giropay} from '@icons/giropay.svg';
import {ReactComponent as wechatpay} from '@icons/wechatpay.svg';
import {ReactComponent as sepa} from '@icons/sepa.svg';
import {ReactComponent as sofort} from '@icons/sofort.svg';
import {ReactComponent as bacs} from '@icons/bacs.svg';
import {ReactComponent as konibi} from '@icons/konibi.svg';
import {ReactComponent as oxxo} from '@icons/oxxo.svg';
import {ReactComponent as p24} from '@icons/p24.svg';
import {ReactComponent as successCheck} from '@icons/successCheck.svg';
import {ReactComponent as authentication} from '@icons/authentication.svg';
import {ReactComponent as decline} from '@icons/decline.svg';
import {ReactComponent as arrowRight} from '@icons/arrowRight.svg';
import {ReactComponent as arrowLeft} from '@icons/arrowLeft.svg';
import {ReactComponent as chevronDown} from '@icons/chevronDown.svg';
import {ReactComponent as checkbox} from '@icons/checkbox.svg';
import {ReactComponent as coupon} from '@icons/coupon.svg';
import {ReactComponent as card} from '@icons/card.svg';
import {ReactComponent as returns} from '@icons/returns.svg';
import {ReactComponent as mail} from '@icons/mail.svg';
import {ReactComponent as infoCircle} from '@icons/infoCircle.svg';
import {ReactComponent as swap} from '@icons/swap.svg';
import {ReactComponent as plus} from '@icons/plus.svg';
import {ReactComponent as affirm} from '@icons/affirm.svg';
import {ReactComponent as klarna} from '@icons/klarna.svg';
import {ReactComponent as afterpayClearpay} from '@icons/afterpayClearpay.svg';

const ICONS = {
  singlePurchase,
  singlePurchaseGray,
  subscription,
  subscriptionGray,
  chevronRight,
  chevronRightWhite,
  stripe,
  features,
  mobile,
  desktop,
  info,
  dropdownChevron,
  googlePay,
  applePay,
  lock,
  check,
  plainCheck,
  restart,
  tooltipArrow,
  bank,
  cancel,
  giropay,
  ideal,
  boleto,
  alipay,
  wechatpay,
  sepa,
  sofort,
  bacs,
  konibi,
  oxxo,
  p24,
  successCheck,
  authentication,
  decline,
  arrowRight,
  arrowLeft,
  chevronDown,
  checkbox,
  coupon,
  card,
  returns,
  mail,
  infoCircle,
  swap,
  plus,
  affirm,
  klarna,
  afterpayClearpay,
  ...flagIcons,
};

export type IconsKey = keyof typeof ICONS;

type Props = {
  name: IconsKey;
  className?: string;
  width?: string | number;
  height?: string | number;
  color?: string;
  opacity?: string | number;
};

function Icon({
  name,
  className,
  width,
  height,
  color,
  opacity,
}: Props): ReactElement {
  return (
    <InlineSVG
      className={className}
      svg={ICONS[name]}
      width={width}
      height={height}
      color={color}
      opacity={opacity}
    />
  );
}

export default Icon;

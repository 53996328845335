import { ReactElement } from "react";
import OrderDetails from "./OrderDetails";
import PaymentForm from "./PaymentForm";
import "./FakeCheckout.css";
import { BillingPeriods, BillingTypes, BorderStyles, CheckoutModes } from "@helpers/types";
import { FontStyles } from "@/client/helpers/fontHelper";

type Props = {
  bgColor: string
  hasCustomBgColor: boolean
  billingPeriod: BillingPeriods
  hasBillingAndShipping: boolean
  hasCoupons: boolean
  hasFreeTrial: boolean
  hasShippingRate: boolean
  hasTaxes: boolean
  hasPhoneNumber: boolean
  hasPolicies: boolean
  mode: CheckoutModes
  billingType: BillingTypes
  hasUpsells: boolean
  borderStyle: BorderStyles
  hasCrossSells: boolean
  fontStyle: FontStyles
}

// FakeCheckout is stubbed with placeholders and is used on Step 2
function FakeCheckout({
  bgColor,
  hasCustomBgColor,
  billingPeriod,
  hasBillingAndShipping,
  hasCoupons,
  hasFreeTrial,
  hasShippingRate,
  hasTaxes,
  hasPhoneNumber,
  hasPolicies,
  mode,
  billingType,
  hasUpsells,
  borderStyle,
  hasCrossSells,
  fontStyle,
}: Props): ReactElement {
  /* PaymentForm internally deals with preset brand color themes */
  const customBrandColor = hasCustomBgColor ? bgColor : ''
  const font = fontStyle === "System" ? "system-ui" : fontStyle
  return (
    <div className="FakeCheckout">
      <OrderDetails
        bgColor={bgColor}
        billingPeriod={billingPeriod}
        hasCoupons={hasCoupons}
        hasFreeTrial={hasFreeTrial}
        hasShippingRate={hasShippingRate}
        hasTaxes={hasTaxes}
        hasPolicies={hasPolicies}
        mode={mode}
        billingType={billingType}
        hasUpsells={hasUpsells}
        hasCrossSells={hasCrossSells}
        borderStyle={borderStyle}
        fontStyle={font}
      />
      <PaymentForm
        mode={mode}
        customBrandColor={customBrandColor}
        hasBillingAndShipping={hasBillingAndShipping}
        hasPolicies={hasPolicies}
        hasPhoneNumber={hasPhoneNumber}
        borderStyle={borderStyle}
        fontStyle={font}
      />
    </div>
  );
}

export default FakeCheckout;

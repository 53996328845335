import { useState, useRef, ReactElement } from 'react';

import {IS_MOBILE} from '@helpers/browsers';
import Icon from '@base/Icon';

import './TestInputs.css';
import classNames from 'classnames';

function copyToClipboard(valueToCopy: string) {
  const el = document.createElement('textarea');
  el.setAttribute('readonly', '');
  el.setAttribute('style', "position: 'absolute'; left: '-9999px'");
  el.value = valueToCopy;

  if (document.body) {
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body && document.body.removeChild(el);
  }
}

type CardDotsProps = {
  color: 'green' | 'blue' | 'red'; // Keep in sync with supported colors found in CSS
};

function CardDots({color}: CardDotsProps) {
  return (
    <div className="CardDots">
      <div className={`CardDot CardDot--${color}`}></div>
      <div className={`CardDot CardDot--${color}`}></div>
      <div className={`CardDot CardDot--${color}`}></div>
      <div className={`CardDot CardDot--${color}`}></div>
    </div>
  );
}

const CLICK_VERB = IS_MOBILE ? 'Tap' : 'Click';

const TEST_ENTRIES = {
  card: {
    header: 'test cards',
    description: (
      <>
        {CLICK_VERB} to copy the card number or use any of{' '}
        <a
          href="https://stripe.com/docs/testing#cards"
          rel="noopener noreferrer"
          target="_blank">
          our test cards
        </a>
        . Use any future expiration date and three-number CVC.
      </>
    ),
    entries: [
      {
        color: 'green',
        label: (
          <>
            <Icon name="plainCheck" width={12} height={12} />
            Success
          </>
        ),
        valueToCopy: '4242424242424242',
        content: (
          <>
            <CardDots color="green" />
            4242
          </>
        ),
      },
      {
        color: 'blue',
        label: (
          <>
            <Icon name="authentication" width={12} height={12} />
            Authentication
          </>
        ),
        valueToCopy: '4000000000003220',
        content: (
          <>
            <CardDots color="blue" />
            3155
          </>
        ),
      },
      {
        color: 'red',
        label: (
          <>
            <Icon name="decline" width={12} height={12} />
            Decline
          </>
        ),
        valueToCopy: '4000000000000002',
        content: (
          <>
            <CardDots color="red" />
            0002
          </>
        ),
      },
    ],
  },
  coupon: {
    header: 'test coupons',
    description: `${CLICK_VERB} to copy the promotion code you want to apply.`,
    entries: [
      {
        color: 'green',
        label: (
          <>
            <Icon name="plainCheck" width={12} height={12} />
            10% off
          </>
        ),
        valueToCopy: 'SAVE10',
        content: 'SAVE10',
      },
      {
        color: 'red',
        label: (
          <>
            <Icon name="decline" width={12} height={12} />
            Expired
          </>
        ),
        valueToCopy: 'SUMMER20',
        content: 'SUMMER20',
      },
    ],
  },
  // todo sepa (when supported by checkout)
  // todo bacs debit (when supported by checkout)
};

type Inputs = keyof typeof TEST_ENTRIES;

type Props = {
  input: Inputs;
  focusedInput?: string;
  minimize?: boolean;
};

function TestInputs({input, focusedInput, minimize}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  // We only show the TestInputs if the user hasn't already opened it.
  let userHasOpenedInputOnce = useRef(false);
  if (!isOpen && !minimize && focusedInput === input && !userHasOpenedInputOnce.current) {
    setIsOpen(true);
  }
  const classes = classNames(
    'TestInputs',
    {
      'TestInputs--open': isOpen && !minimize 
    },
    {
      'TestInputs--minimize ': minimize
    },
  );

  // by default show cards
  const inputEntryData = TEST_ENTRIES[input];
  const [hasCopied, setHasCopied] = useState(false);

  return (
    <div className={classes}>
      <div
        className="TestInputs-Header"
        onClick={() => {
          if (!userHasOpenedInputOnce.current && isOpen) {
            userHasOpenedInputOnce.current = true;
          }
          setIsOpen(!isOpen);
        }}>
        <label>
          <Icon
            className={`TestInputs-HeaderIcon ${
              input === 'coupon' ? 'TestInputs-HeaderIcon--coupon' : ''
            }`}
            name={input}
          />
          {inputEntryData.header}
        </label>
        <div className="TestInputs-Chevron">
          <Icon name="chevronDown" height={12} width={12} />
        </div>
      </div>
      <div className="TestInputs-Entries">
        {inputEntryData.entries.map(({color, label, valueToCopy, content}) => {
          return (
            <button
              key={valueToCopy}
              className={`TestInputs-Entry TestInputs-Entry--${color} ${
                hasCopied && 'TestInputs-Entry--copied'
              }`}
              onClick={() => {
                setHasCopied(true);
                copyToClipboard(valueToCopy);
              }}
              onMouseEnter={() => {
                // setHasCopied to false onMouseEnter
                // into another test entry so 'Copy' shows
                if (hasCopied) {
                  setHasCopied(false);
                }
              }}>
              <label>{label}</label>
              <div className="TestInputs-EntryContent">{content}</div>
            </button>
          );
        })}
      </div>
      <p className="TestInputs-Description">{inputEntryData.description}</p>
    </div>
  );
}

export default TestInputs;

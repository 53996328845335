import { ReactElement, ReactNode } from 'react';

type Props = {
  className: string,
  children: ReactNode
}

function Column({className = '', children}: Props): ReactElement {
  return <div className={`Column ${className}`}>{children}</div>;
}

export default Column;

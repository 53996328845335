import { ReactElement } from 'react';
import {Link} from 'react-router-dom';

import Icon from '@base/Icon';
import ExternalLink from '@base/ExternalLink';
import IconButton from '@components/IconButton';
import './SuccessOverlay.css';

const SuccessOverlay = (): ReactElement => {
  return (
    <div className="SuccessOverlay">
      <Icon name="check" />
      <h2>Payment success</h2>
      <p>After a successful payment, the customer returns to your website.</p>
      <div className="SuccessOverlay-Buttons">
        <Link to={'/'}>
          <IconButton secondary={true} icon="restart" animationForIcon="rotate">
            Restart demo
          </IconButton>
        </Link>
        <ExternalLink
          grayText
          href="https://stripe.com/payments/checkout"
          iconColor="inherit">
          Learn more
        </ExternalLink>
      </div>
    </div>
  );
};

export default SuccessOverlay;

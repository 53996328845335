import { ReactElement } from 'react';

import Dropdown from '@base/Dropdown/Dropdown';
import { Wallets } from '@helpers/types';

type WalletOption = {
  label: string;
  value: Wallets
}
const WALLETS: WalletOption[] = [
  {label: '', value: 'applePay'},
  {label: '', value: 'googlePay'},
];

// todo we should default based on browser!
const DEFAULT_WALLET: WalletOption = {label: 'Google Pay', value: 'googlePay'};

type Props = {
  selectedWallet: Wallets;
  onChange: (value: Wallets) => void;
};

function WalletDropdown({selectedWallet, onChange}: Props): ReactElement {
  return (
    <Dropdown
      label="Wallet"
      selectedValue={selectedWallet || DEFAULT_WALLET.value}
      options={WALLETS}
      onChange={onChange}
      tooltipContent={
        'By default, Checkout will dynamically show the wallet option that your customer has enabled on their browser or device.'
      }
    />
  );
}

export default WalletDropdown;

/**
 * This should keep parity with the color helper in stripe-js-v3:
 * https://git.corp.stripe.com/stripe-internal/stripe-js-v3/blob/master/src/lib/hosted/helpers/color.ts
 */
import {hcl as _toHcl} from 'd3-color';
import { BrandColorOption } from '@components/base/Toggle/BrandColor';

const LIGHT_THRESHOLD = 60;
export const ADJUSTMENT_THRESHOLD = 0.5;

type RGB = {
  r: number;
  g: number;
  b: number;
};

type HCLColor = {
  h: number;
  c: number;
  l: number;
  opacity: number;
  // Returns true if and only if the color is displayable on
  // standard hardware.
  displayable: () => boolean;
  // Returns a brighter copy of this color. If k is specified, it
  // controls how much brighter the returned color should be.
  brighter: (k: number) => HCLColor;
  // Returns a darker copy of this color. If k is specified, it
  // controls how much darker the returned color should be.
  darker: (k: number) => HCLColor;
  // Returns a hexadecimal string representing this color.
  hex: () => string;
  // Returns a string representing this color.
  // e.g.
  //   rgba(223, 60, 177, 0)
  //   rgb(250, 89, 202)
  toString: () => string;
  // Returns an object containing the rgb values of the color.
  rgb: () => RGB;
};

export type RelatedColors = {
  isLight: boolean;
  backgroundColor: string;
  color: string;
  transparent: string;
  light: string;
  dark: string;
};

export const toHcl = (...args: any): HCLColor => {
  // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
  const hcl = _toHcl(...args);

  if (isNaN(hcl.h) && isNaN(hcl.c) && isNaN(hcl.l)) {
    throw new Error('Invalid hex color passed to toHcl');
  }

  return hcl;
};

const isLightHcl = (hcl: HCLColor) => hcl.l > LIGHT_THRESHOLD;

export const isLightHex = (hex: string) => isLightHcl(toHcl(hex));

export const generateRelatedColors = (hex: string): RelatedColors => {
  const hcl = toHcl(hex);

  const isLight = isLightHcl(hcl);

  // Detect if the provided color is light or dark, and depending on that, pick
  // the related colors
  let textColorHex = isLight ? '#000000' : '#ffffff';
  if (isLight) {
    const textHcl = toHcl(hcl.h, hcl.c, hcl.l - 60);
    if (textHcl.displayable()) {
      textColorHex = textHcl.hex();
    }
  }

  const rgbLight = hcl.brighter(ADJUSTMENT_THRESHOLD).toString();
  const rgbDark = hcl.darker(ADJUSTMENT_THRESHOLD).toString();
  const rgbTransparent = toHcl(hcl.h, hcl.c, hcl.l, 0).toString();

  return {
    isLight,
    backgroundColor: hex,
    color: textColorHex,
    transparent: rgbTransparent,
    light: rgbLight,
    dark: rgbDark,
  };
};

const sRgbToLinearRgb = function sRgbToLinearRgb(value: number) {
  const slope = 12.92;
  const srgb = 0.04045;

  if (value <= srgb) {
    return value / slope;
  } else {
    return ((value + 0.055) / 1.055) ** 2.4;
  }
};

export const relativeLuminance = (hex: string) => {
  const rgb = toHcl(hex).rgb();
  const r = sRgbToLinearRgb(rgb.r / 255);
  const g = sRgbToLinearRgb(rgb.g / 255);
  const b = sRgbToLinearRgb(rgb.b / 255);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const getContrastRatio = (hex1: string, hex2: string) => {
  const aL = relativeLuminance(hex1);
  const bL = relativeLuminance(hex2);

  const minL = Math.min(aL, bL);
  const maxL = Math.max(aL, bL);

  return (maxL + 0.05) / (minL + 0.05);
};

export function isValidHex(color: string): boolean {
  const regex = /^#[0-9A-F]{6}$/i;
  return regex.test(color);
}

export function getDefaultBrandColorOptions (isSubscriptionMode: boolean, customBrandColor: string = '#ffffff'): BrandColorOption[] {  
  return isSubscriptionMode
    ? [
        {key: 'defaultLight', topColor: '#009688', bottomColor: '#00766C', value: true, isCustom: false},
        {key: 'defaultDark', topColor: '#ffffff', bottomColor: '#00766C', value: false, isCustom: false},
        {key: 'custom', topColor: customBrandColor, bottomColor: customBrandColor, value: false, isCustom: true},
      ]
    : [
        {key: 'defaultLight', topColor: '#ffffff', bottomColor: '#192552', value: false, isCustom: false},
        {key: 'defaultDark', topColor: '#192552', bottomColor: '#192552', value: true, isCustom: false},
        {key: 'custom', topColor: customBrandColor, bottomColor: customBrandColor, value: false, isCustom: true},
      ];
}
import { 
    TypedUseSelectorHook, 
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector, 
    useStore as useReduxStore 
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import interfaceReducer from '@slices/interfaceSlice';
import settingsReducer from '@slices/settingsSlice';
import {saveConfigMiddleware} from "@helpers/middleware";

const store = configureStore({
    middleware: [saveConfigMiddleware],
    reducer: {
        interface: interfaceReducer,
        settings: settingsReducer
    },
});

export type Store = typeof store;
export type RootState = ReturnType<Store['getState']>;
export type Dispatch = Store['dispatch'];
export const useDispatch: () => Dispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useStore: () => Store = useReduxStore ;
export default store;
import { ReactElement } from 'react';
import Placeholder from '@base/Placeholder';
import Icon from '@base/Icon';
import Checkbox from './Checkbox';
import {motion, AnimatePresence, AnimateSharedLayout} from 'framer-motion';
import { IoMdRadioButtonOn } from 'react-icons/io';
import { FiCircle } from 'react-icons/fi';
import { BorderStyles, CheckoutModes } from '@helpers/types';
import classnames from 'classnames';
import { isLightHex } from '../../helpers/colorHelper';

import './PaymentForm.css';
import { FontStyles } from '@/client/helpers/fontHelper';

type Props = {
  hasBillingAndShipping: boolean,
  hasPhoneNumber: boolean,
  hasPolicies: boolean,
  mode: CheckoutModes,
  borderStyle: BorderStyles,
  customBrandColor: string,
  fontStyle?: FontStyles,
}

function PaymentForm({mode, hasBillingAndShipping, hasPolicies, hasPhoneNumber, borderStyle, customBrandColor, fontStyle}: Props): ReactElement {
  const isSubscriptionMode = mode === 'subscription';
  const isPaymentMode = !isSubscriptionMode;
  const showBillingAddress = isSubscriptionMode && hasBillingAndShipping;
  const showShippingAddress = isPaymentMode && hasBillingAndShipping;
  const showPhoneNumberUnderContact = (isSubscriptionMode && hasPhoneNumber) || (isPaymentMode && hasPhoneNumber && !hasBillingAndShipping);
  const showPhoneNumberUnderShipping = isPaymentMode && hasPhoneNumber && hasBillingAndShipping;

  let buttonColor = customBrandColor;
  if (!buttonColor) {
    if (isSubscriptionMode) {
      buttonColor = '#00766C';
    } else {
      buttonColor = '#192552';
    }
  }

  const buttonTextColor = isLightHex(buttonColor) ? 'rgba(26, 26, 26, 0.6)' : 'rgba(255, 255, 255, 0.6)';

  const transition = {
    duration: 0.6,
    delay: 0,
    stiffness: 400,
    damping: 40,
    mass: 1.2,
  };
  const opacityAnimation = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
  };

  const getSubmitBorderStyleClass = () => {
    if (borderStyle === 'sharp') {
      return 'PaymentForm-Submit--Sharp';
    } else if (borderStyle === 'pill') {
      return 'PaymentForm-Submit--Pill';
    } else {
      return 'PaymentForm-Submit--Rounded';
    }
  }

  const paymentFormSubmitClassNames = classnames('PaymentForm-Submit', getSubmitBorderStyleClass());
  
  const getContactInfoInputBorderStyleClass = () => {
    if (borderStyle === 'sharp') {
      return 'PaymentForm-ContactInfoInput--Sharp';
    } else if (borderStyle === 'pill') {
      return 'PaymentForm-ContactInfoInput--Pill';
    } else {
      return 'PaymentForm-ContactInfoInput--Rounded';
    }
  }

  const getAddressInputBorderStyleClass = () => {
    if (borderStyle === 'sharp') {
      return 'PaymentForm-AddressInput--Sharp';
    } else if (borderStyle === 'pill') {
      return 'PaymentForm-AddressInput--Pill';
    } else {
      return 'PaymentForm-AddressInput--Rounded';
    }
  }

  return (
    <div className="PaymentForm" style={{ fontFamily: `${fontStyle}, serif` }} >
      <div className="PaymentForm-Wrapper">
        {/* Fake Wallet Button */}

        <Placeholder borderStyle={borderStyle} height={24} fakeInput fullWidth />
        {/* Divider */}
        <div className="PaymentForm-DividerWrapper">
          <div className="PaymentForm-Divider" />
          <Placeholder borderStyle={borderStyle} width={56} />
          <div className="PaymentForm-Divider" />
        </div>

        <AnimatePresence initial={false} exitBeforeEnter>
          <AnimateSharedLayout>
            {showShippingAddress && (
              <motion.div
                className="PaymentForm-InputLine"
                transition={transition}
                {...opacityAnimation}
              >
                <Placeholder borderStyle={borderStyle} width={72} height={14} />
              </motion.div>
            )}

            {(!hasPhoneNumber || showPhoneNumberUnderShipping) && (
              <motion.div
                layout className="PaymentForm-InputLine"
                transition={transition}
                {...opacityAnimation}>
                <Placeholder borderStyle={borderStyle} width={16} />
                <Placeholder borderStyle={borderStyle} height={20} fakeInput fullWidth />
              </motion.div>
            )}

            {showPhoneNumberUnderContact && (
              <div
                className="PaymentForm-AddressAnimationWrapper"
                style={{
                  // Children are position: absolute
                  // so we set height on container
                  height: 56
                }}
            >
              {showPhoneNumberUnderContact ? (
                  <motion.div
                      key="shippingForm"
                      className="PaymentForm-InputLine PaymentForm-Address"
                      transition={transition}
                      {...opacityAnimation}
                  >
                    <label className="PaymentForm-ContactInfoLabel">Contact Information</label>
                    <div className="PaymentForm-AddressInputWrapper">
                      <div className="PaymentForm-ContactInfoInputWrapper">
                        <input
                          className={`PaymentForm-ContactInfoInput ${getContactInfoInputBorderStyleClass()}`}
                          placeholder="email@example.com"
                          readOnly
                        />
                        <Icon name="mail" className="PaymentForm-ContactInfoInput--IconLeft"/>
                      </div>
                      <div className="PaymentForm-ContactInfoInputWrapper">
                        <Icon name="infoCircle" className="PaymentForm-ContactInfoInput--IconRight"/>
                        <Icon name="us" className="PaymentForm-ContactInfoInput--IconLeft"/>
                        <input
                          className= {`PaymentForm-ContactInfoInput ${getContactInfoInputBorderStyleClass()}`}
                          placeholder="+1 (234) 567-8910"
                          readOnly
                        />
                      </div>
                    </div>
                  </motion.div>
              ) : null}
            </div>
            )}
            

            <div
              className="PaymentForm-AddressAnimationWrapper"
              style={{
                // Children are position: absolute
                // so we set height on container
                // After adding phone number, the height should be adjusted accordingly.
                height: showPhoneNumberUnderShipping ? 178 : (showShippingAddress ? 159 : 52),
              }}
            >
              <AnimatePresence initial={false}>
                {showShippingAddress ? (
                  <motion.div
                    key="shippingForm"
                    className="PaymentForm-InputLine PaymentForm-Address"
                    transition={transition}
                    {...opacityAnimation}
                  >
                    <label className="Shipping-Method-Label">Shipping Address</label>
                    <div className="PaymentForm-AddressInputWrapper"
                       style={{
                          height: hasPhoneNumber ? 75 : 56,
                       }}
                    >
                      <input
                        className={`PaymentForm-AddressInput PaymentForm-AddressInput--No-Bot-Shadow ${getAddressInputBorderStyleClass()}`}
                        placeholder="Name"
                        readOnly
                      />
                      <div className={`PaymentForm-AddressInput ${getAddressInputBorderStyleClass()}`}>
                        United States
                        <Icon name="chevronDown" />
                      </div>
                      <input
                        className={`PaymentForm-AddressInput ${getAddressInputBorderStyleClass()}`}
                        placeholder="Address"
                        readOnly
                      />
                      {hasPhoneNumber ? (
                        <motion.div
                            key="phoneForm"
                            transition={transition}
                            {...opacityAnimation}
                        >
                          <div className="PaymentForm-ContactInfoInputWrapper PaymentForm-ContactInfoInput--phone">
                            <Icon name="us" className="PaymentForm-ContactInfoInput--IconLeft"/>
                            <Icon name="infoCircle" className="PaymentForm-ContactInfoInput--IconRight"/>
                            <input
                                className={`PaymentForm-ContactInfoInput ${getContactInfoInputBorderStyleClass()}`}
                                placeholder="+1 (234) 567-8910"
                                readOnly
                            />
                          </div>
                        </motion.div>
                      ) : null}
                    </div>
                    <div className="PaymentForm-AddressManually">
                      Enter address manually
                    </div>
                    <label className="Shipping-Method-Label">Shipping Method</label>
                    <div className="PaymentForm-AddressInputWrapper PaymentForm-AddressInputWrapper--shipping">
                      <div className={`PaymentForm-AddressInput PaymentForm-AddressInput--No-Bot-Shadow Shipping-Method-Input-Wrapper ${getAddressInputBorderStyleClass()}`}>
                        <div className="Shipping-Method-Input-Row">
                          <IoMdRadioButtonOn className="Shipping-Method-Radio-Button-Checked"/>
                          <div className="Shipping-Method-Input-Row-Buddle">
                            <div className="Shipping-Method-Free-Shipping-Front">Free shipping</div>
                            <div className="Shipping-Method-Free-Shipping-Days-Front">5-7 business days</div>
                          </div>
                          <div className="Shipping-Method-Input-Row"></div>
                          <div className="Shipping-Method-Free-Shipping-Front">Free</div>
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                    
                      <div className={`PaymentForm-AddressInput PaymentForm-AddressInput--No-Top-Shadow ${getAddressInputBorderStyleClass()}`}>
                        <div className="Shipping-Method-Input-Row">
                          <FiCircle className="Shipping-Method-Radio-Button"/>
                          <div className="Shipping-Method-Input-Row-Buddle">
                            <div>Next day air</div>
                            <div className="Shipping-Method-Free-Shipping-Days-Front">1 business day</div>
                          </div>
                          <div className="Shipping-Method-Input-Row"></div>
                          <div>$15.00</div>
                        </div>
                      </div>
                    </div>

                  </motion.div>
                ) : (
                  <motion.div
                    key="placeholderForShippingForm"
                    style={{position: 'absolute', width: '100%'}}
                    className="PaymentForm-InputLine"
                    transition={transition}
                    {...opacityAnimation}
                  >
                    <Placeholder borderStyle={borderStyle} width={52} />
                    <Placeholder borderStyle={borderStyle} height={36} fakeInput fullWidth />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {!showShippingAddress && (
              <div
                className="PaymentForm-AddressAnimationWrapper"
                style={{
                  // Children are position: absolute
                  // so we set height on container
                  height: showShippingAddress ? 14 : 32,
                }}
              >
                <AnimatePresence initial={false}>
                  <motion.div
                    className="PaymentForm-InputLine"
                    transition={transition}
                    {...opacityAnimation}
                  >
                    <Placeholder borderStyle={borderStyle} width={44} />
                    <Placeholder borderStyle={borderStyle} height={20} fakeInput fullWidth />
                  </motion.div>
                </AnimatePresence>
              </div>
            )}

            <div
              className="PaymentForm-AddressAnimationWrapper"
              style={{
                // Children are position: absolute
                // so we set height on container
                height: showBillingAddress ? 64 : 48,
              }}
            >
              <AnimatePresence initial={false}>
                {showBillingAddress ? (
                  <motion.div
                    key="billingForm"
                    className="PaymentForm-InputLine PaymentForm-Address"
                    transition={transition}
                    {...opacityAnimation}
                  >
                    <label>Billing Address</label>
                    <div className="PaymentForm-AddressInputWrapper">
                      <div className={`PaymentForm-AddressInput ${getAddressInputBorderStyleClass()}`}>
                        United States
                        <Icon name="chevronDown" />
                      </div>
                      <input
                        className={`PaymentForm-AddressInput ${getAddressInputBorderStyleClass()}`}
                        placeholder="Address"
                        readOnly
                      />
                    </div>
                    <div className="PaymentForm-AddressManually">
                      Enter address manually
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key="placeholderForBillingForm"
                    style={{position: 'absolute', width: '100%'}}
                    className="PaymentForm-InputLine"
                    transition={transition}
                    {...opacityAnimation}
                  >
                    <Placeholder borderStyle={borderStyle} width={52} />
                    <Placeholder borderStyle={borderStyle} height={36} fakeInput fullWidth />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <AnimatePresence initial={false}>
              <div className="PaymentForm-AddressAnimationWrapper">
                {showShippingAddress && (
                  <motion.div
                    className="PaymentForm-BillingSameAsShipping"
                    transition={transition}
                    {...opacityAnimation}
                  >
                    <Checkbox /> Billing address is same as shipping
                  </motion.div>
                )}
              </div>
            </AnimatePresence>

            {/* Policies */}

            {hasPolicies ? (
              <motion.div
                {...opacityAnimation}
                key="policies"
                className="PaymentForm-Policies"
              >
                <Icon
                  className="PaymentForm-PoliciesIcon"
                  name="returns"
                  width={8}
                  height={8}
                />
                <p className="PaymentForm-PoliciesText">
                  {isPaymentMode
                    ? 'Free returns and exchanges'
                    : 'Eligible for a refund'}
                </p>
              </motion.div>
            ) : null}

            {/* Submit button */}
            <motion.div
              layout
              className={paymentFormSubmitClassNames}
              style={{backgroundColor: buttonColor, color: buttonTextColor}}
            >
              {isSubscriptionMode ? 'Subscribe' : 'Pay $129.00'}
            </motion.div>

            {/* Subscription disclaimer */}
            {isSubscriptionMode && (
              <motion.div layout className="PaymentForm-Footer">
                <Placeholder borderStyle={borderStyle} width={160} height={6} style={{marginBottom: 2}} />
                <Placeholder borderStyle={borderStyle} width={168} height={6} style={{marginBottom: 2}} />
                <Placeholder borderStyle={borderStyle} width={120} height={6} style={{marginBottom: 2}} />
              </motion.div>
            )}
          </AnimateSharedLayout>
        </AnimatePresence>
      
      </div>
    </div>
  );
}

export default PaymentForm;

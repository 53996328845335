import { ReactElement, ReactNode, MouseEventHandler } from 'react';
import {Link} from 'react-router-dom';
import Icon, { IconsKey } from '@base/Icon';

import './NextStep.css';

type Props = {
  to: string;
  children: ReactNode;
  icon?: IconsKey;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

function NextStep({to, children, icon = 'arrowRight', onClick}: Props): ReactElement {
  return (
    <Link className="NextStep" to={to} onClick={onClick}>
      <span className="NextStep-Text">{children}</span>
      <Icon className="NextStep-Icon" name={icon} color="white" />
    </Link>
  );
}

export default NextStep;

import {ReactElement} from 'react';
import classnames from 'classnames';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import StepOne from '@pages/StepOne';
import StepTwo from '@pages/StepTwo';
import StepThree from '@pages/StepThree';
import SuccessOverlay from '@components/SuccessOverlay';
import Navbar from '@components/Navbar';
import {IS_FIREFOX} from '@helpers/browsers';
import Demo from '@components/Demo';
import {IS_DEMO_MODE} from '@helpers/constants';
import './App.css';

function App(): ReactElement {
  const demoClasses = classnames('Demo', {
    'Demo--Firefox': IS_FIREFOX,
    'demo-mode': IS_DEMO_MODE,
    'background': !IS_DEMO_MODE,
  });

  return (
    <Router>
      <div className={demoClasses}>
        <Switch>
          {/* This route is used for iframing on stripe.com */}
          <Route path='/demo'>
            <Demo />
          </Route>
          <Route>
            <Navbar />
            <div className="App">
              <main>
                <Switch>
                  <Route path="/success">
                    <div className="SuccessScreen">
                      <SuccessOverlay />
                    </div>
                  </Route>
                  <Route path="/preview"><StepThree /></Route>
                  <Route path="/configure"><StepTwo /></Route>
                  <Route path="/"><StepOne /></Route>
                </Switch>
              </main>
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

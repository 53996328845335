import { FakeURLs } from "@components/FakeChrome";

export const checkoutModes = ['payment', 'subscription'] as const;
export const billingPeriods = ['monthly', 'yearly'] as const;
export const billingTypes = ['flat', 'metered'] as const;
export const viewSizes = ['desktop', 'mobile'] as const;
export const wallets = ['applePay', 'googlePay'] as const;
export const borderStyles = ['rounded', 'sharp', 'pill'] as const;

export type CheckoutModes = typeof checkoutModes[number];
export type BillingPeriods = typeof billingPeriods[number];
export type BillingTypes = typeof billingTypes[number];
export type ViewSizes = typeof viewSizes[number];
export type Wallets = typeof wallets[number];
export type BorderStyles = typeof borderStyles[number];
export type BusinessModelOption = Record<CheckoutModes, {url: FakeURLs, checkoutUrl: FakeURLs, img: string }>;

import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from '@store';  // import the root state from your store
import { saveConfig } from '@helpers/storage';

export const saveConfigMiddleware: Middleware = (
    storeApi: MiddlewareAPI<Dispatch<AnyAction>, RootState>
) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    const result = next(action);
    if (action.type.startsWith('settings')) {
        const {settings} = storeApi.getState();
        saveConfig(settings);
    }
    return result;
};
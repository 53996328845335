import { ReactElement } from 'react';

import Toggle, { ToggleProps } from './Toggle';

import './Toggle.css';

type SliderProps = ToggleProps & {
  selected: boolean;
  onChange: (val: boolean) => void;
};

function Slider(props: SliderProps): ReactElement {
  const {
    selected,
    onChange,
    disabled,
    label,
    tooltipContent,
    className,
    tooltipDistance,
    disabledTooltipContent,
  } = props

  return (
    <Toggle
      type='slider'
      label={label}
      tooltipContent={tooltipContent}
      className={className}
      tooltipDistance={tooltipDistance}
      disabledTooltipContent={disabledTooltipContent}
      disabled={disabled}
    >
      <button 
        className="Slider" 
        disabled={disabled} 
        onClick={() => onChange(!selected)}
      >
        <input
          className="Slider-Checkbox"
          type="checkbox"
          checked={selected}
          readOnly
          tabIndex={-1}
        />
        <label className="Slider-Slider" />
      </button>
    </Toggle>
  );
}

export default Slider;
import { ReactElement } from 'react';

import Icon from '@base/Icon';
import Toggle, { ToggleProps } from './Toggle';
import { isLightHex } from '../../../helpers/colorHelper';

import './Toggle.css';

export type BrandColorOption = {
  key: string;
  topColor: string;
  bottomColor: string;
  value: boolean;
  isCustom: boolean;
};

type BrandColorProps = ToggleProps & {
  onChange: (val: BrandColorOption) => void;
  selectedKey: string;
  options?: BrandColorOption[];
  setCustomBrandColor: (val: string) => void;
}

function BrandColor(props: BrandColorProps): ReactElement {
  const {
    selectedKey,
    onChange,
    disabled,
    options = [],
    label,
    tooltipContent,
    className,
    tooltipDistance,
    disabledTooltipContent,
    setCustomBrandColor,
  } = props

  const customOption = options.find((option) => option.isCustom) as BrandColorOption;
  const customOptionIconColor = isLightHex(customOption.topColor) ? '#1A1A1A' : '#FFFFFF';

  return (
    <Toggle
      type='brandColor'
      label={label}
      tooltipContent={tooltipContent}
      className={className}
      tooltipDistance={tooltipDistance}
      disabledTooltipContent={disabledTooltipContent}
      disabled={disabled}
    >
      <div className="BrandColor">
        {options.map(option => (
          <div key={option.key} className="BrandColor-Swatch-Container">
            <button
              className={`BrandColor-Swatch ${
                selectedKey === option.key ? 'BrandColor-Swatch--selected' : ''
              } ${
                option.isCustom ? 'BrandColor-Swatch--custom' : ''
              }`}
              onClick={() => { 
                onChange(option)
                if (!option.isCustom) {
                  setCustomBrandColor(option.topColor);
                }
              }}
              style={{backgroundColor: option.topColor}}
            >
              {option.isCustom ?
                <input 
                  className="BrandColor-Swatch-Input"
                  type="color" 
                  onChange={e => setCustomBrandColor(e.target.value) }
                />
                : null
              }
            </button>
            {option.isCustom ?
              <div className="BrandColor-Swatch-Icon">
                <Icon name="plus" color={customOptionIconColor} />
              </div>
              : null
            }
          </div>
        ))}
      </div>
    </Toggle>
  );
}

export default BrandColor;
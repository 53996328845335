import  { ReactElement, ReactNode } from "react";
import classnames from 'classnames';
import './ChromeDialog.css';

type Props = {
  children: ReactNode,
  isMobileView: boolean
}

const ChromeDialog = ({children, isMobileView}: Props): ReactElement => {
  const className = classnames('ChromeDialog-Dialog', {
    'ChromeDialog-Dialog--mobileView': isMobileView,
  });

  return (
    <div className="ChromeDialog">
      <div className={className}>{children}</div>
    </div>
  );
};

export default ChromeDialog;

import { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import Icon from '@base/Icon';
import { IS_DEMO_MODE } from '@helpers/constants';
import Tooltip from '@base/Tooltip';
import './FakeChrome.css';

export type FakeURLs =
  | 'checkout.stripe.com'
  | 'powdur.me'
  | 'pay.powdur.me'
  | 'togethere.work'
  | 'pay.togethere.work';

type Props = {
  children: ReactNode;
  url?: FakeURLs;
  isMobile?: boolean;
  isDesktop?: boolean;
  autoHeight?: boolean;
  smallHeight?: boolean;
  showTopButtons?: boolean;
  smallUrlBar?: boolean;
  className?: string;
  allowDomainCustomization?: boolean;
  useCustomDomain?: boolean;
  setUseCustomDomain?: (value: boolean) => void;
};

// FakeChrome can be Desktop width, Mobile width or custom
function FakeChrome({
  children,
  url = 'checkout.stripe.com',
  isMobile = false,
  isDesktop = false,
  autoHeight = false,
  smallHeight = false,
  showTopButtons = false,
  smallUrlBar = false,
  className = '',
  allowDomainCustomization = false,
  useCustomDomain = false,
  setUseCustomDomain = () => {},
}: Props): ReactElement {  
  const fakeChromeClassNames = classnames('FakeChrome', className, {
    'FakeChrome--desktop': isDesktop,
    'FakeChrome--mobile': isMobile,
    'FakeChrome--auto': autoHeight,
    'FakeChrome--smallHeight': smallHeight,
  });
  const fakeChromeWrapperClassNames = classnames('FakeChrome-Wrapper', {
    'FakeChrome-MobileWrapper': isMobile,
  });
  const topBarClassNames = classnames('FakeChrome-TopBar', {
    'FakeChrome-TopBar--mobile': isMobile,
  });
  const urlBarClassNames = classnames('FakeChrome-URLBar', {
    'FakeChrome-URLBar--small': smallUrlBar,
    'FakeChrome-URLBar--mobile': isMobile,
  });
  const customUrlButtonText = isDesktop
    ? useCustomDomain
      ? 'Back to default'
      : 'Use your own domain'
    : null;

  const handleCustomDomainToggle = () => {
    setUseCustomDomain(!useCustomDomain);

    if (IS_DEMO_MODE) {
      window.parent.postMessage(JSON.stringify({
        type: 'custom-domain-toggle',
        state: !useCustomDomain
      }), "*")
    }
  }

  return (
    <div className={fakeChromeClassNames}>
      <div className={fakeChromeWrapperClassNames}>
        <div className={topBarClassNames}>
          {(isDesktop || showTopButtons) && (
            <div className="FakeChrome-TopBarButtons">
              <div className="FakeChrome-TopBarButton" />
              <div className="FakeChrome-TopBarButton" />
              <div className="FakeChrome-TopBarButton" />
            </div>
          )}
          <div className={urlBarClassNames}>
            <ul className="FakeChrome-URLBarList">
              <li className="FakeChrome-URLInputListItem">
                <Icon className="FakeChrome-URLBarIcon" name="lock" />
                {allowDomainCustomization && !IS_DEMO_MODE ? (
                  <Tooltip
                    contextClassName={"FakeChrome--URLTooltip"}
                    context={url}
                    content={
                      <p>
                        Bring your own <a 
                          href="https://stripe.com/docs/payments/checkout/custom-domains" 
                          target="_blank" 
                          rel="noopener noreferrer"
                        > custom domain 
                        </a> to Stripe Checkout for $10.00 USD per month.
                      </p>
                    }
                    position="above"
                    distance={6}
                    allowPointerEvents
                  />) : <>{url}</>
                }
              </li>
              {allowDomainCustomization && (
                <li className="FakeChrome-TopBarUrlChange">
                  <div
                    role="button"
                    onClick={handleCustomDomainToggle}
                    className="FakeChrome-TopBarUrlChangeButton"
                  >
                    <Icon
                      className="FakeChrome-TopBarUrlChangeButtonIcon"
                      name="swap"
                    />
                    {customUrlButtonText}
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="FakeChrome-CheckoutPreview">{children}</div>
      </div>
    </div>
  );
}

export type FakeChromeProps = Props;
export default FakeChrome;

import {ReactElement, ReactNode} from 'react';
import classnames from 'classnames';
import Icon from '@base/Icon';
import './ExternalLink.css';

type Props = {
  href: string;
  children: ReactNode;
  inverse?: boolean;
  grayText?: boolean;
  iconColor?: string;
  includeIcon?: boolean;
};

function ExternalLink({
  href,
  children,
  inverse = false,
  grayText = false,
  iconColor,
  includeIcon = true,
}: Props): ReactElement {
  const classes = classnames('ExternalLink', {
    'ExternalLink--white': inverse,
    'ExternalLink--gray': !inverse && grayText,
  });
  const color = inverse ? 'white' : '#6C8EEF';

  return (
    <a href={href} className={classes}>
      <span className="ExternalLink-Text">{children}</span>
      {includeIcon && (
        <Icon
          className="ExternalLink-Icon"
          name="arrowRight"
          color={iconColor || color}
        />
      )}
    </a>
  );
}

export default ExternalLink;

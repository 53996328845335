import WebFont from "webfontloader";

const FONTS = [
    "System",
    "Be Vietnam Pro",
    "Bitter",
    "Chakra Petch",
    "Hahmlet",
    "Inconsolata",
    "Inter",
    "Lato",
    "Lora",
    "M PLUS 1 Code",
    "Montserrat",
    "Noto Sans JP",
    "Noto Sans",
    "Noto Serif",
    "Nunito",
    "Open Sans",
    "Pridi",
    "PT Sans",
    "PT Serif",
    "Raleway",
    "Roboto",
    "Roboto Slab",
    "Source Sans Pro",
    "Titillium Web",
    "Ubuntu Mono",
    "Zen Maru Gothic",
];
  
export type FontStyles = typeof FONTS[number];
export const FontList = Object.values(FONTS);

FONTS.forEach((font) => {
  if (font !== 'System') {
    WebFont.load({
      google: {
        families: [font],
      },
    });
  }
})
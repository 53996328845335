import Icon from '@base/Icon';
import Badge from '../base/Badge';
import Placeholder from '@base/Placeholder';
import {motion, AnimatePresence, AnimateSharedLayout} from 'framer-motion';
import classnames from 'classnames';
import {isLightHex} from '../../helpers/colorHelper';

import powdurProduct from '@assets/powdur.jpg';
import togethereProduct from '@assets/togethere.png';
import togethereCrossSell from '@assets/togethere-cross-sell.png';
import powdurLogo from '@assets/powdur-logo.png';
import powdurProduct2 from '@assets/powdur2.jpeg';
import powdurCrossSell from '@assets/powdur-cross-sell.png';
import powdurBgColorLogo from '@assets/powdur-bgcolor-logo.png';
import togethereLogo from '@assets/togethere-logo.png';
import togethereBgColorLogo from '@assets/togethere-bgcolor-logo.png';

import './OrderDetails.css';
import {
  BillingPeriods,
  BillingTypes,
  BorderStyles,
  CheckoutModes,
} from '@helpers/types';
import {ReactElement, useMemo} from 'react';
import {FontStyles} from '@/client/helpers/fontHelper';

type Props = {
  bgColor: string;
  billingPeriod: BillingPeriods;
  hasCoupons: boolean;
  hasFreeTrial: boolean;
  hasShippingRate: boolean;
  hasTaxes: boolean;
  hasPolicies: boolean;
  mode: CheckoutModes;
  billingType: BillingTypes;
  hasUpsells: boolean;
  borderStyle: BorderStyles;
  hasCrossSells: boolean;
  fontStyle: FontStyles;
};

function OrderDetails({
  bgColor,
  billingPeriod,
  hasCoupons,
  hasFreeTrial,
  hasShippingRate,
  hasTaxes,
  hasPolicies,
  mode,
  billingType,
  hasUpsells,
  borderStyle,
  hasCrossSells,
  fontStyle,
}: Props): ReactElement {
  const isPaymentMode = mode === 'payment';
  const isSubscriptionMode = !isPaymentMode;
  const hasBgColor = !isLightHex(bgColor);
  const isMetered = billingType === 'metered';
  const isMonthly = billingPeriod === 'monthly';

  let logo;
  let price;
  let name;
  let thenPrice;

  if (isPaymentMode) {
    name = 'Powdur';
    price = '$129.00';
    logo = hasBgColor ? powdurBgColorLogo : powdurLogo;
  } else {
    name = 'Togethere';
    logo = hasBgColor ? togethereBgColorLogo : togethereLogo;

    if (hasFreeTrial) {
      price = '30 days free';

      if (isMetered) {
        thenPrice = isMonthly
          ? 'then $18.00 per unit, billed monthly based on usage'
          : 'then $180.00 per unit, billed yearly based on usage';
      } else {
        thenPrice = isMonthly
          ? 'then $18.00 per month'
          : 'then $180.00 per year';
      }
    } else if (isMetered) {
      price = 'Price varies';
      thenPrice = isMonthly
        ? 'then $18.00 per unit, billed monthly based on usage'
        : 'then $180.00 per unit, billed yearly based on usage';
    } else {
      price = isMonthly ? '$18.00' : '$180.00';
    }
  }

  // when showDetailedView is false, we display the single large image UI
  // when true, the image gets smaller and becomes a line item
  const showDetailedView =
    isPaymentMode ||
    hasTaxes ||
    hasShippingRate ||
    hasCoupons ||
    hasUpsells ||
    hasCrossSells;

  const showSubtotalAndFooter = hasShippingRate || hasTaxes || hasCoupons;

  // If the bg color is dark, forces text and placeholders to be white for
  // reading contrast
  const fontColorWhite = hasBgColor;

  const transition = {
    duration: 0.3,
    delay: 0,
    stiffness: 400,
    damping: 40,
    mass: 1.2,
  };
  const opacityAnimation = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
  };

  const productDetailsClass = `OrderDetails-ProductAndDetails ${
    hasUpsells ? 'OrderDetails-ProductAndDetails--bordered' : ''
  } ${fontColorWhite ? 'OrderDetails-ProductAndDetails--light' : ''}`;

  const dividerClass = fontColorWhite
    ? 'OrderDetails-Divider OrderDetails-Divider--white'
    : 'OrderDetails-Divider';

  const iconColor = fontColorWhite
    ? 'hsla(0, 0%, 100%, 1)'
    : 'rgba(26, 26, 26, 0.5)';

  const borderColor = fontColorWhite
    ? 'hsla(0, 0%, 100%, 0.2)'
    : 'rgba(26, 26, 26, 0.1)';

  const crossSellBoxBackgroundColor = fontColorWhite
    ? 'hsla(0, 0%, 100%, 0.1)'
    : 'rgba(26, 26, 26, 0.05)';

  const crossSellIconColor = fontColorWhite
    ? 'hsla(0, 0%, 100%, 1)'
    : 'rgba(26, 26, 26, 1)';

  const getOrderDetailsProductImageBorderStyleClass = () => {
    if (borderStyle === 'sharp') {
      return 'OrderDetails-ProductImage--Sharp';
    } else if (borderStyle === 'pill') {
      return 'OrderDetails-ProductImage--Pill';
    } else {
      return 'OrderDetails-ProductImage--Rounded';
    }
  };

  const orderDetailsProductImageBorderStyleClassNames = classnames(
    'OrderDetails-ProductImage',
    getOrderDetailsProductImageBorderStyleClass()
  );

  const getOrderDetailsProductImageLargeBorderStyleClass = () => {
    if (borderStyle === 'sharp') {
      return 'OrderDetails-ProductImage--large--Sharp';
    } else if (borderStyle === 'pill') {
      return 'OrderDetails-ProductImage--large--Pill';
    } else {
      return 'OrderDetails-ProductImage--large--Rounded';
    }
  };

  const orderDetailsProductImageLargeBorderStyleClassNames = classnames(
    'OrderDetails-ProductImage--large',
    'OrderDetails-ProductImage',
    getOrderDetailsProductImageLargeBorderStyleClass()
  );

  const crossSellBoxBorderRadius = useMemo(() => {
    if (borderStyle === 'sharp') {
      return '0px';
    } else if (borderStyle === 'pill') {
      return '9px';
    } else {
      return '3px';
    }
  }, [borderStyle]);

  const couponBorderRadius = useMemo(() => {
    if (borderStyle === 'sharp') {
      return '0px';
    } else if (borderStyle === 'pill') {
      return '9px';
    } else {
      return '3px';
    }
  }, [borderStyle]);

  const productDetailBorderRadius = useMemo(() => {
    if (borderStyle === 'sharp') {
      return '0px';
    } else if (borderStyle === 'pill') {
      return '9px';
    } else {
      return '3px';
    }
  }, [borderStyle]);

  const lineItem = (image: string) => {
    return (
      <div className="OrderDetails-ProductLineItem">
        {/* Product Image */}
        <motion.img
          layout
          className={orderDetailsProductImageBorderStyleClassNames}
          alt="product"
          src={image}
          animate={{
            width: 20,
            height: 20,
            top: -1,
          }}
          initial={{
            width: 160,
            height: 160,
          }}
          exit={{width: 160, height: 160}}
          transition={transition}
        />
        <motion.div
          layout
          className="OrderDetails-DetailLines"
          transition={transition}
          {...opacityAnimation}
        >
          <motion.div layout className="OrderDetails-DetailLine">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Placeholder
                width={54}
                light={fontColorWhite}
                borderStyle={borderStyle}
              />
              <Placeholder
                width={16}
                light={fontColorWhite}
                style={{marginBottom: 0}}
                borderStyle={borderStyle}
              />
            </div>
            <Placeholder
              width={28}
              style={{marginLeft: 'auto'}}
              light={fontColorWhite}
              borderStyle={borderStyle}
            />
          </motion.div>
        </motion.div>
      </div>
    );
  };

  return (
    <div
      className="OrderDetails"
      style={{
        backgroundColor: bgColor,
        color: fontColorWhite ? '#ffffff' : '#303030',
        fontFamily: `"${fontStyle}", serif`,
      }}
    >
      <AnimateSharedLayout>
        <div className="OrderDetails-Wrapper">
          <div className="OrderDetails-Header">
            <Icon
              className="OrderDetails-HeaderArrow"
              name="arrowLeft"
              color={iconColor}
              width={6}
              height={6}
            />
            <img className="OrderDetails-HeaderLogo" alt="logo" src={logo} />
            <p
              className={`OrderDetails-HeaderBusiness ${
                fontColorWhite ? 'OrderDetails-HeaderBusiness--white' : ''
              }`}
            >
              {name}
            </p>
          </div>
          <div className="OrderDetails-Product">
            <div className="OrderDetails-ProductPriceWrapper">
              <Placeholder
                light={fontColorWhite}
                width={isSubscriptionMode ? 136 : 32}
                borderStyle={borderStyle}
              />
              <h1
                className={`OrderDetails-ProductPrice ${
                  fontColorWhite ? 'OrderDetails-ProductPrice--white' : ''
                }`}
                style={{marginBottom: hasFreeTrial ? 4 : undefined}}
              >
                {price}
                {isSubscriptionMode && !hasFreeTrial && (
                  <div
                    className={`OrderDetails-ProductPriceSubs ${
                      fontColorWhite
                        ? 'OrderDetails-ProductPriceSubs--white'
                        : ''
                    }`}
                  >
                    per {billingPeriod === 'monthly' ? 'month' : 'year'}
                  </div>
                )}
              </h1>
              {isSubscriptionMode && (hasFreeTrial || isMetered) && (
                <div className="OrderDetails-ThenPrice">{thenPrice}</div>
              )}
            </div>
            <AnimatePresence initial={false} exitBeforeEnter>
              {showDetailedView ? (
                <>
                  <motion.div
                    layout
                    className={productDetailsClass}
                    key="OrderDetailsWrapper"
                    style={{borderRadius: productDetailBorderRadius}}
                  >
                    <div className="OrderDetails-ProductLineItems">
                      {/* Payment mode has one extra product line item */}
                      {isPaymentMode ? (
                        <>
                          {lineItem(powdurProduct)}
                          {lineItem(powdurProduct2)}
                        </>
                      ) : (
                        <>{lineItem(togethereProduct)}</>
                      )}
                    </div>

                    <AnimatePresence>
                      {hasUpsells && (
                        <motion.div
                          className={`OrderDetails-Upsells ${
                            fontColorWhite ? 'OrderDetails-Upsells--light' : ''
                          }`}
                          transition={transition}
                          {...opacityAnimation}
                        >
                          <div
                            className={`FakeSlider ${
                              fontColorWhite ? 'FakeSlider--light' : ''
                            }`}
                          />
                          <span>Save with annual billing</span>
                          <Badge>$36 off</Badge>
                          <span className="OrderDetails-AnnualPrice">
                            $180.00/year
                          </span>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                  {showSubtotalAndFooter && (
                    <motion.div
                      layout
                      className="OrderDetails-AdditionalDetails"
                      key="AdditionalDetails"
                      transition={transition}
                      {...opacityAnimation}
                    >
                      {/* Subtotal */}
                      <motion.div layout className="OrderDetails-DetailLine">
                        <Placeholder
                          width={28}
                          light={fontColorWhite}
                          style={{marginBottom: 0}}
                          borderStyle={borderStyle}
                        />
                        <Placeholder
                          width={28}
                          style={{marginLeft: 'auto', marginBottom: 0}}
                          light={fontColorWhite}
                          borderStyle={borderStyle}
                        />
                      </motion.div>
                      {/* Divider line */}
                      <motion.div layout className={dividerClass} />
                      <motion.div
                        layout
                        className="OrderDetails-TaxesCouponsWrapper"
                      >
                        {hasCoupons && (
                          <motion.div
                            layout
                            className="OrderDetails-DetailLine"
                            transition={transition}
                            {...opacityAnimation}
                          >
                            <motion.div
                              layout
                              className={`OrderDetails-PromoCode ${
                                fontColorWhite
                                  ? 'OrderDetails-PromoCode--white'
                                  : ''
                              }`}
                              style={{borderRadius: couponBorderRadius}}
                            >
                              Add promotion code
                            </motion.div>
                          </motion.div>
                        )}
                        {hasShippingRate && (
                          <motion.div
                            layout
                            className="OrderDetails-DetailLine"
                            transition={transition}
                            {...opacityAnimation}
                          >
                            {hasShippingRate && (
                              <motion.div
                                layout
                                className="OrderDetails-DetailLine OrderDetails-Shipping"
                                transition={transition}
                                {...opacityAnimation}
                              >
                                <motion.label
                                  layout
                                  className={`OrderDetails-DetailLabel OrderDetails-Shipping ${
                                    hasBgColor
                                      ? 'OrderDetails-DetailLabel--white'
                                      : ''
                                  }`}
                                >
                                  <div className="Shipping-Method-Input-Wrapper OrderDetails-Shipping">
                                    <div className="Shipping-Method-Input-Row">
                                      <div>
                                        <div className="Shipping-Method-Free-Shipping-Front">
                                          Free shipping
                                        </div>
                                        <div className="Shipping-Method-Free-Shipping-Days-Front">
                                          5-7 business days
                                        </div>
                                      </div>
                                      <div className="Shipping-Method-Input-Row"></div>
                                      <div className="Shipping-Method-Free-Shipping-Front">
                                        Free
                                      </div>
                                    </div>
                                  </div>
                                </motion.label>
                              </motion.div>
                            )}
                          </motion.div>
                        )}
                        {hasTaxes && (
                          <motion.div
                            layout
                            className="OrderDetails-DetailLine"
                            transition={transition}
                            {...opacityAnimation}
                          >
                            <motion.label
                              layout
                              className={`OrderDetails-DetailLabel ${
                                hasBgColor
                                  ? 'OrderDetails-DetailLabel--white'
                                  : ''
                              }`}
                            >
                              Sales tax
                            </motion.label>
                            <Placeholder
                              animateAsPartOfLayout
                              width={68}
                              light={fontColorWhite}
                              style={{
                                marginLeft: 'auto',
                                marginBottom: 0,
                              }}
                              borderStyle={borderStyle}
                            />
                          </motion.div>
                        )}
                      </motion.div>
                      {/* Divider line */}
                      <motion.div layout className={dividerClass} />
                      {/* Total amount */}
                      <motion.div layout className="OrderDetails-DetailLine">
                        <Placeholder
                          width={52}
                          light={fontColorWhite}
                          style={{marginBottom: 0}}
                          borderStyle={borderStyle}
                        />
                        <Placeholder
                          width={32}
                          light={fontColorWhite}
                          style={{marginLeft: 'auto', marginBottom: 0}}
                          borderStyle={borderStyle}
                        />
                      </motion.div>
                    </motion.div>
                  )}
                </>
              ) : (
                <>
                  {/* Single product, large image view */}
                  <motion.div
                    initial={{width: 160, height: 160}}
                    animate={{width: 160, height: 160}}
                    exit={{width: 0, height: 0}}
                    className="OrderDetails-ProductImageContainer"
                  >
                    <motion.img
                      layout
                      initial={{
                        width: 20,
                        height: 20,
                      }}
                      animate={{
                        width: 160,
                        height: 160,
                      }}
                      exit={{
                        width: 20,
                        height: 20,
                      }}
                      transition={transition}
                      style={{position: 'absolute'}}
                      className={
                        orderDetailsProductImageLargeBorderStyleClassNames
                      }
                      alt="product"
                      src={togethereProduct}
                    />
                  </motion.div>
                </>
              )}
              {hasCrossSells ? (
                <div className="OrderDetails-CrossSell">
                  <p style={{fontSize: '7px', opacity: '70%'}}>
                    Add to your order
                  </p>
                  <div
                    className="OrderDetails-CrossSell-Box"
                    style={{
                      border: `0.5px solid ${borderColor}`,
                      borderRadius: crossSellBoxBorderRadius,
                      background: crossSellBoxBackgroundColor,
                    }}
                  >
                    <div className="OrderDetails-ProductLineItem">
                      {/* Product Image */}
                      <motion.img
                        layout
                        className={
                          orderDetailsProductImageBorderStyleClassNames
                        }
                        alt="product"
                        src={
                          isPaymentMode ? powdurCrossSell : togethereCrossSell
                        }
                        animate={{
                          width: 20,
                          height: 20,
                          top: -1,
                        }}
                        initial={{
                          width: 160,
                          height: 160,
                        }}
                        exit={{width: 160, height: 160}}
                        transition={transition}
                      />
                      <motion.div
                        layout
                        className="OrderDetails-DetailLines"
                        transition={transition}
                        {...opacityAnimation}
                      >
                        <motion.div layout className="OrderDetails-DetailLine">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Placeholder
                              width={78}
                              light={fontColorWhite}
                              borderStyle={borderStyle}
                            />
                            <Placeholder
                              width={60}
                              light={fontColorWhite}
                              style={{marginBottom: 0}}
                              borderStyle={borderStyle}
                            />
                          </div>
                          <div style={{marginLeft: 'auto', marginRight: '4px'}}>
                            <div
                              style={{
                                display: 'inline',
                                height: '6px',
                                width: '6px',
                                marginRight: '4px',
                              }}
                            >
                              <Icon
                                className="OrderDetails-CrossSell-PlusIcon"
                                name="plus"
                                color={crossSellIconColor}
                              />
                            </div>
                            <p
                              style={{
                                display: 'inline',
                                verticalAlign: 'middle',
                                fontSize: '7px',
                                fontWeight: 500,
                              }}
                            >
                              Add
                            </p>
                          </div>
                        </motion.div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              ) : null}
            </AnimatePresence>
          </div>
          <motion.div
            layout
            className="OrderDetails-Footer"
            transition={transition}
          >
            <Placeholder
              width={52}
              light={fontColorWhite}
              borderStyle={borderStyle}
            />
            <div
              className={`OrderDetails-FooterDivider ${
                fontColorWhite ? 'OrderDetails-FooterDivider--white' : ''
              }`}
            />
            <AnimatePresence exitBeforeEnter>
              {hasPolicies ? (
                <motion.p
                  className={`OrderDetails-FooterPolicies ${
                    fontColorWhite ? 'OrderDetails-FooterPolicies--white' : ''
                  }`}
                  transition={transition}
                  {...opacityAnimation}
                >
                  Legal&nbsp;&nbsp;Refunds&nbsp;&nbsp;Contact
                </motion.p>
              ) : (
                <motion.div transition={transition} {...opacityAnimation}>
                  <Placeholder
                    width={60}
                    light={fontColorWhite}
                    borderStyle={borderStyle}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </AnimateSharedLayout>
    </div>
  );
}

export default OrderDetails;

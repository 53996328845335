import { ButtonGroupOption } from "@components/base/Toggle/ButtonGroup";
import { DropdownOption } from "@components/base/Dropdown/DropdownSelect";
import { FontList, FontStyles } from "./fontHelper";
import { BillingPeriods, BillingTypes, BorderStyles, BusinessModelOption } from "./types";

// Detecting if we're on the path used to iframe on stripe.com
export const IS_DEMO_MODE = window.location.pathname === '/demo';

export const BusinessModelPreviews: BusinessModelOption = {
  payment: {
    url: 'powdur.me',
    checkoutUrl: 'pay.powdur.me',
    img: '/images/Powdur.png',
  },
  subscription: {
    url: 'togethere.work',
    checkoutUrl: 'pay.togethere.work',
    img: '/images/Togethere.png',
  },
};

export const billingPeriodOptions: ButtonGroupOption<BillingPeriods>[] = [
    {label: 'Monthly', value: 'monthly'},
    {label: 'Yearly', value: 'yearly'},
];

export const billingTypeOptions: ButtonGroupOption<BillingTypes>[] = [
    {label: 'Flat rate', value: 'flat'},
    {label: 'Metered', value: 'metered'},
];

export const borderStyleOptions: DropdownOption<BorderStyles>[] = [
    {label: 'Rounded', value: 'rounded'},
    {label: 'Sharp', value: 'sharp'},
    {label: 'Pill', value: 'pill'},
];

export const fontStyleOptions: DropdownOption<FontStyles>[] = [
    {value: "System", label: "System (default)"},
    ...FontList.filter(font => font !== "System").map((font: string) => ({value: font, label: font}))
];
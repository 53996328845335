import Column from '@base/Column';
import FakeChrome from '@components/FakeChrome';
import './StepOne.css';
import {ReactElement} from 'react';
import { useSelector } from '@store';
import { BusinessModelPreviews } from '@helpers/constants';

function StepOne(): ReactElement {
  const mode = useSelector(state => state.settings.mode);

  return (
    <Column className={`StepOne-Preview StepOne-Preview--${mode}`}>
      <FakeChrome autoHeight isDesktop url={BusinessModelPreviews[mode].url}>
        <img
          className="StepOne-ImagePreview StepOne-ImagePreview--payment"
          src={BusinessModelPreviews.payment.img}
          alt="Website preview for the payment business model."
        />
        <img
          className="StepOne-ImagePreview StepOne-ImagePreview--subscription"
          src={BusinessModelPreviews.subscription.img}
          alt="Website preview for the subscription business model."
        />
      </FakeChrome>
    </Column>
  );
}

export default StepOne;

import {MouseEventHandler, ReactElement} from 'react';
import classnames from 'classnames';
import Icon, {IconsKey} from '@base/Icon';
import IconButton from '@components/IconButton';
import ChromeDialog from '@components/ChromeDialog';
import ExternalLink from '@base/ExternalLink';

import './CheckoutOverlay.css';

export type OverlayOptions =
  | 'ach'
  | 'alipay'
  | 'afterpay_clearpay'
  | 'affirm'
  | 'applePay'
  | 'bacs_debit'
  | 'au_becs_debit'
  | 'boleto'
  | 'giropay'
  | 'googlePay'
  | 'ideal'
  | 'konibi'
  | 'klarna'
  | 'oxxo'
  | 'p24'
  | 'sepa_debit'
  | 'sofort'
  | 'us_bank_account'
  | 'wechat_pay'
  | 'success';

export const iconsForOverlay: Record<
  OverlayOptions,
  {name: IconsKey; width?: number; height?: number}
> = {
  ach: {
    name: 'bank',
  },
  affirm: {
    name: 'affirm',
    width: 32,
    height: 32,
  },
  afterpay_clearpay: {
    name: 'afterpayClearpay',
    width: 32,
    height: 32,
  },
  alipay: {
    name: 'alipay',
    width: 32,
    height: 32,
  },
  applePay: {
    name: 'applePay',
    width: 80,
    height: 40,
  },
  bacs_debit: {
    name: 'bacs',
  },
  au_becs_debit: {
    name: 'bank',
  },
  boleto: {
    name: 'boleto',
  },
  giropay: {
    name: 'giropay',
    width: 32,
    height: 32,
  },
  googlePay: {
    name: 'googlePay',
    width: 80,
    height: 40,
  },
  ideal: {
    name: 'ideal',
  },
  klarna: {
    name: 'klarna',
    width: 32,
    height: 32,
  },
  konibi: {
    name: 'konibi',
  },
  oxxo: {
    name: 'oxxo',
  },
  p24: {
    name: 'p24',
    width: 32,
    height: 32,
  },
  sepa_debit: {
    name: 'sepa',
    width: 32,
    height: 32,
  },
  sofort: {
    name: 'sofort',
    width: 32,
    height: 32,
  },
  us_bank_account: {
    name: 'bank',
    width: 32,
    height: 32,
  },
  wechat_pay: {
    name: 'wechatpay',
  },
  success: {
    name: 'check',
  },
};

export const headerForOverlay: Record<OverlayOptions, string> = {
  ach: 'ACH',
  affirm: 'Affirm',
  alipay: 'Alipay',
  afterpay_clearpay: 'Afterpay',
  applePay: 'Apple Pay',
  bacs_debit: 'Bacs Direct Debit',
  au_becs_debit: 'AU BECS Direct Debit',
  boleto: 'Boleto Bancário',
  giropay: 'giropay',
  googlePay: 'Google Pay',
  ideal: 'iDEAL',
  konibi: 'Konibi',
  klarna: 'Klarna',
  oxxo: 'OXXO',
  p24: 'Przelewy24',
  sepa_debit: 'SEPA Direct Debit',
  sofort: 'Sofort',
  wechat_pay: 'WeChat Pay',
  success: 'Payment success',
  us_bank_account: 'US bank account',
};

export const descriptionForOverlay: Record<OverlayOptions, string> = {
  ach: "You have successfully collected your customer's details and are now authorized to debit their bank account.",
  affirm:
    'Your customer can now complete their purchase by authenticating the payment through Affirm.',
  afterpay_clearpay:
    'Your customer can now complete their purchase by authenticating the payment through Afterpay.',
  alipay:
    'Your customer can now complete their purchase by authenticating the payment through Alipay.',
  applePay:
    'Your customer can now check out in seconds by simply authenticating the payment through Face ID or Touch ID.',
  bacs_debit:
    "You have successfully collected your customer's details and are now authorized to debit their bank account.",
  au_becs_debit:
    "You have successfully collected your customer's details and are now authorized to debit their bank account.",
  boleto:
    'Your customer will be presented with a bank invoice which they can pay at an authorized location.',
  giropay:
    'Your customer would be redirected to their online banking environment to authenticate the payment.',
  googlePay:
    'Your customer can now check out in seconds by simply authenticating the payment.',
  ideal:
    'Your customer would be redirected to their online banking environment to authenticate the payment.',
  klarna:
    'Your customer can now complete their purchase by authenticating the payment through Klarna.',
  konibi:
    'Your customer will be redirected to the Konbini page to complete the purchase.',
  oxxo: 'Your customer will be presented with a voucher which they can pay at any OXXO authorized location.',
  p24: 'Your customer will be redirected to complete the purchase.',
  sepa_debit:
    "You have successfully collected your customer's details and are now authorized to debit their bank account.",
  sofort:
    'Your customer can now complete their purchase by authenticating the payment through Sofort.',
  us_bank_account:
    'Your customer can now complete their purchase by authenticating the payment through their bank.',
  wechat_pay:
    'Your customer can now complete their purchase by authenticating the payment through WeChat.',
  success: 'After a successful payment, the customer returns to your website.',
};

type Props = {
  overlay: OverlayOptions;
  onSuccess?: MouseEventHandler<HTMLButtonElement>;
  isMobileView: boolean;
};

const CheckoutOverlay = ({
  overlay,
  onSuccess,
  isMobileView,
}: Props): ReactElement | null => {
  const icon = iconsForOverlay[overlay];
  const buttonsClassNames = classnames('CheckoutOverlay-Buttons', {
    'CheckoutOverlay-Buttons--columnView': isMobileView,
  });

  return (
    <ChromeDialog isMobileView={isMobileView}>
      <div className="CheckoutOverlay CheckoutOverlay--fadein">
        <Icon {...icon} />
        <h2>{headerForOverlay[overlay]}</h2>
        <p>{descriptionForOverlay[overlay]}</p>
        <div className={buttonsClassNames}>
          <IconButton
            primary={!isMobileView}
            secondary={isMobileView}
            icon="restart"
            onClick={onSuccess}
            animationForIcon="rotate"
          >
            Restart demo
          </IconButton>
          <ExternalLink
            grayText
            href="https://stripe.com/docs/payments/checkout"
            iconColor="#8792A2"
          >
            Explore the docs
          </ExternalLink>
        </div>
      </div>
    </ChromeDialog>
  );
};

export default CheckoutOverlay;

import { ReactElement } from 'react';

import classNames from 'classnames';

import './Checkout.css';

type Props = {
  url: string;
  blur?: boolean;
};

function Checkout({url, blur = false}: Props): ReactElement {
  if (url === '') {
    // some sort of loading state
    return (
      <p>Loading</p>
    );
  }

  const classes = classNames([
    'CheckoutDemo',
    {
      'CheckoutDemo--blur': blur,
    },
  ]);

  return (
    <iframe
      title="Checkout Demo"
      name="checkout-demo"
      id="checkout-demo"
      width="100%"
      className={classes}
      height="100%"
      style={{
        border: '0',
        overflow: 'hidden',
      }}
      tabIndex={0}
      // todo figure out how to fake demo mode on localhost
      src={url}
    />
  );
}

export default Checkout;

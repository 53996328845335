import { ReactElement, MouseEventHandler } from 'react';
import classNames from 'classnames';

import Icon, { IconsKey } from '@base/Icon';

import type {CheckoutModes} from '@helpers/types';

import './BusinessModelButton.css';

const title: Record<CheckoutModes, string> = {
  payment: 'One-time payments',
  subscription: 'Recurring payments',
};

const description: Record<CheckoutModes, string> = {
  payment: 'Digital or physical goods and services',
  subscription: 'Subscription or SaaS businesses',
};

const icon: Record<CheckoutModes, IconsKey> = {
  payment: 'singlePurchase',
  subscription: 'subscription',
};

type Props = {
  type: CheckoutModes;
  selected: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

function BusinessModelButton({type, selected, onClick}: Props): ReactElement {
  const classes = classNames([
    'BusinessModelButton',
    {
      'BusinessModelButton--selected': selected,
    },
  ]);

  return (
    <button className={classes} onClick={onClick}>
      <div className="BusinessModelButton-IconWrapper">
        <Icon name={icon[type]} width={12} height={12} />
      </div>
      <div className="BusinessModelButton-TextWrapper">
        <h3>{title[type]}</h3>
        <p>{description[type]}</p>
      </div>
    </button>
  );
}

export default BusinessModelButton;

import  { ReactElement, ReactNode } from "react";
import "./Badge.css";

type Props = {
  children: ReactNode
}

function Badge({ children }: Props): ReactElement {
  return <span className="Badge">{children}</span>;
}

export default Badge;

import { ReactElement } from 'react';
import './Checkbox.css';

// Note: This checkbox is for display purposes only.
function Checkbox(): ReactElement {
  return (
    <div className="CheckboxField CheckboxField--checked">
      <div className="Checkbox-InputContainer">
        <input type="checkbox" className="Checkbox-Input" defaultChecked />
        <span className="Checkbox-StyledInput" />
      </div>
    </div>
  );
}

export default Checkbox;

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ViewSizes, Wallets } from '@helpers/types'
import { OverlayOptions } from '@components/CheckoutOverlay';
import Country from '@/types/country';

export interface InterfaceState {
  view: ViewSizes;
  focusedInput: string;
  overlay: OverlayOptions | null;
  animateFromStepThree: boolean;
  location: Country;
  wallet: Wallets;
}

export const initialState: InterfaceState = { 
  view: window.innerWidth > 1024 ? 'desktop' : 'mobile',
  focusedInput: '',
  overlay: null,
  animateFromStepThree: false,
  location: 'us',
  wallet: 'applePay',
}

const InterfaceSlice = createSlice({
  name: 'interface',
  initialState: initialState,
  reducers: {
    setView: (state, action: PayloadAction<InterfaceState['view']>) => {
      state.view = action.payload;
    },
    setFocusedInput: (state, action: PayloadAction<InterfaceState['focusedInput']>) => {
      state.focusedInput = action.payload;
    },
    setOverlay: (state, action: PayloadAction<InterfaceState['overlay']>) => {
      state.overlay = action.payload;
    },
    setAnimateFromStepThree: (state, action: PayloadAction<InterfaceState['animateFromStepThree']>) => {
      state.animateFromStepThree = action.payload;
    },
    setLocation: (state, action: PayloadAction<InterfaceState['location']>) => {
        state.location = action.payload;
    },
    setWallet: (state, action: PayloadAction<InterfaceState['wallet']>) => {
        state.wallet = action.payload;
    },
    setLocationAndClearOverlay: (state, action: PayloadAction<InterfaceState['location']>) => {
        state.location = action.payload;
        state.overlay = null;
    },
    setStepTwoRenderSettings: (state) => {
        state.animateFromStepThree = false;
        state.view = 'desktop';
    },
  },
})

export const { 
  setView,
  setFocusedInput,
  setOverlay,
  setAnimateFromStepThree,
  setLocation,
  setWallet,
  setLocationAndClearOverlay,
  setStepTwoRenderSettings,
} = InterfaceSlice.actions;

export default InterfaceSlice.reducer;
import { BrandColorOption } from "@components/base/Toggle/BrandColor";
import { SettingsState, defaultState } from "@slices/settingsSlice";
import { FontList } from "./fontHelper";
import { loadConfig } from "./storage";
import { BillingPeriods, BillingTypes, BorderStyles, CheckoutModes, billingPeriods, billingTypes, borderStyles, checkoutModes } from "./types";
import { isValidHex } from "./colorHelper";

export interface QueryParams {
    mode?: SettingsState['mode'];
    hasFreeTrial?: SettingsState['hasFreeTrial'];
    billingPeriod?: SettingsState['billingPeriod'];
    hasBillingAndShipping?: SettingsState['hasBillingAndShipping'];
    hasTaxes?: SettingsState['hasTaxes'];
    hasPhoneNumber?: SettingsState['hasPhoneNumber'];
    hasCoupons?: SettingsState['hasCoupons'];
    customBrandColor?: SettingsState['customBrandColor'];
    selectedBrandKey?: SettingsState['selectedBrandColorOption']['key'];
    selectedBrandTopColor?: SettingsState['selectedBrandColorOption']['topColor'];
    selectedBrandBottomColor?: SettingsState['selectedBrandColorOption']['bottomColor'];
    selectedBrandValue?: SettingsState['selectedBrandColorOption']['value'];
    selectedBrandIsCustom?: SettingsState['selectedBrandColorOption']['isCustom'];
    hasPolicies?: SettingsState['hasPolicies'];
    billingType?: SettingsState['billingType'];
    hasUpsells?: SettingsState['hasUpsells'];
    borderStyle?: SettingsState['borderStyle'];
    hasCrossSells?: SettingsState['hasCrossSells'];
    fontStyle?: SettingsState['fontStyle'];
    useCustomDomain?: SettingsState['useCustomDomain'];
}

export type ParamsPayload = Record<string, string | boolean | Record<string, unknown>>;

export function getQueryParams(): QueryParams {
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject: Record<string, string | boolean | object> = {};
    for (const [key, value] of queryParams) {
        switch (value) {
        case 'true':
            paramsObject[key] = true;
            break;
        case 'false':
            paramsObject[key] = false;
            break;
        default:
            paramsObject[key] = value;
        }
    }
    return paramsObject;
}

export const getValidatedPayload = (payload: ParamsPayload) => {
    const filteredObject = Object.keys(payload)
        .filter(key => key in defaultState)
        .reduce((result, key) => {
        result[key] = payload[key];
        return result;
    }, {} as ParamsPayload);

    const result: Partial<SettingsState> = {};

    const {
        mode,
        billingPeriod,
        billingType,
        customBrandColor,
        selectedBrandColorOption,
        borderStyle,
        fontStyle,
        ...booleans
    } = filteredObject;

    if (mode && typeof mode === 'string' && checkoutModes.includes(mode as any)) {
        result.mode = mode as CheckoutModes;
    }

    if (billingPeriod && typeof billingPeriod === 'string' && billingPeriods.includes(billingPeriod as any)) {
        result.billingPeriod = billingPeriod as BillingPeriods;
    }
      
    if (billingType && typeof billingType === 'string' && billingTypes.includes(billingType as any)) {
        result.billingType = billingType as BillingTypes;
    }

    if (customBrandColor && typeof customBrandColor === 'string' && isValidHex(customBrandColor)) {
        result.customBrandColor = customBrandColor;
    }

    if (
        selectedBrandColorOption && typeof selectedBrandColorOption === 'object' &&
        'key' in selectedBrandColorOption && typeof selectedBrandColorOption.key === 'string' &&
        'topColor' in selectedBrandColorOption && typeof selectedBrandColorOption.topColor === 'string' &&
        'bottomColor' in selectedBrandColorOption && typeof selectedBrandColorOption.bottomColor === 'string' &&
        'value' in selectedBrandColorOption && typeof selectedBrandColorOption.value === 'boolean' &&
        'isCustom' in selectedBrandColorOption && typeof selectedBrandColorOption.isCustom === 'boolean' &&
        isValidHex(selectedBrandColorOption.topColor) && isValidHex(selectedBrandColorOption.bottomColor)
    ) {
        result.selectedBrandColorOption = selectedBrandColorOption as BrandColorOption;
    }

    if (borderStyle && typeof borderStyle === 'string' && borderStyles.includes(borderStyle as any)) {
        result.borderStyle = borderStyle as BorderStyles;
    }

    if (fontStyle && typeof fontStyle === 'string' && FontList.includes(fontStyle)) {
        result.fontStyle = fontStyle;
    }

    Object.entries(booleans).forEach(([key, value]) => {
        typeof value === 'boolean' && ((result as Record<string, unknown>)[key] = value);
    });

    return result;
}

export const mergeUrlConfigParams = () => {
    const {
        selectedBrandKey,
        selectedBrandTopColor,
        selectedBrandBottomColor,
        selectedBrandValue,
        selectedBrandIsCustom,
        ...queryParams
    } = getQueryParams();
    const config = loadConfig();

    const querySelectedBrandColorOption = {
        key: selectedBrandKey,
        topColor: selectedBrandTopColor,
        bottomColor: selectedBrandBottomColor,
        value: selectedBrandValue,
        isCustom: selectedBrandIsCustom,
    };

    const result = {
        ...config,
        ...queryParams,
    }

    if (
        Object.values(querySelectedBrandColorOption).every(
            (value) => typeof value !== 'undefined'
        )
    ) {
        result.selectedBrandColorOption = querySelectedBrandColorOption;
    }

    return getValidatedPayload(result);
}
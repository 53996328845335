import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BillingPeriods, BillingTypes, BorderStyles, CheckoutModes } from '@helpers/types'
import { ParamsPayload, getValidatedPayload, mergeUrlConfigParams } from '@helpers/params'
import { BrandColorOption } from '@components/base/Toggle/BrandColor';
import { FontStyles } from '@helpers/fontHelper';
import { getDefaultBrandColorOptions } from '@helpers/colorHelper';

export interface SettingsState {
  mode: CheckoutModes;
  hasFreeTrial: boolean;
  billingPeriod: BillingPeriods;
  billingType: BillingTypes;
  hasUpsells: boolean;
  hasBillingAndShipping: boolean;
  hasTaxes: boolean;
  hasPhoneNumber: boolean;
  hasShippingRate: boolean;
  hasCoupons: boolean;
  customBrandColor: string;
  selectedBrandColorOption: BrandColorOption;
  hasPolicies: boolean;
  borderStyle: BorderStyles;
  fontStyle: FontStyles;
  hasCrossSells: boolean;
  useCustomDomain: boolean;
}

export const defaultState: SettingsState = { 
  mode: 'payment',
  hasFreeTrial: false,
  billingPeriod: 'monthly',
  billingType: 'flat',
  hasUpsells: false,
  hasBillingAndShipping: false,
  hasTaxes: false,
  hasPhoneNumber: false,
  hasShippingRate: false,
  hasCoupons: false,
  customBrandColor: '#ffffff',
  selectedBrandColorOption: getDefaultBrandColorOptions(false)[0],
  hasPolicies: false,
  borderStyle: 'rounded',
  fontStyle: 'System',
  hasCrossSells: false,
  useCustomDomain: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: getInitialState(),
  reducers: {
    setMode: (state, action: PayloadAction<SettingsState['mode']>) => {
      state.mode = action.payload;
    },
    setHasFreeTrial: (state, action: PayloadAction<SettingsState['hasFreeTrial']>) => {
      state.hasFreeTrial = action.payload;
    },
    setBillingPeriod: (state, action: PayloadAction<SettingsState['billingPeriod']>) => {
      state.billingPeriod = action.payload;
    },
    setBillingType: (state, action: PayloadAction<SettingsState['billingType']>) => {
      state.billingType = action.payload;
    },
    setHasUpsells: (state, action: PayloadAction<SettingsState['hasUpsells']>) => {
      state.hasUpsells = action.payload;
    },
    setHasBillingAndShipping: (state, action: PayloadAction<SettingsState['hasBillingAndShipping']>) => {
      state.hasBillingAndShipping = action.payload;
    },
    setHasTaxes: (state, action: PayloadAction<SettingsState['hasTaxes']>) => {
      state.hasTaxes = action.payload;
    },
    setHasPhoneNumber: (state, action: PayloadAction<SettingsState['hasPhoneNumber']>) => {
      state.hasPhoneNumber = action.payload;
    },
    setHasShippingRate: (state, action: PayloadAction<SettingsState['hasShippingRate']>) => {
      state.hasShippingRate = action.payload;
    },
    setHasCoupons: (state, action: PayloadAction<SettingsState['hasCoupons']>) => {
      state.hasCoupons = action.payload;
    },
    setCustomBrandColor: (state, action: PayloadAction<SettingsState['customBrandColor']>) => {
      state.customBrandColor = action.payload;
    },
    setSelectedBrandColorOption: (state, action: PayloadAction<SettingsState['selectedBrandColorOption']>) => {
      state.selectedBrandColorOption = action.payload;
    },
    setHasPolicies: (state, action: PayloadAction<SettingsState['hasPolicies']>) => {
      state.hasPolicies = action.payload;
    },
    setBorderStyle: (state, action: PayloadAction<SettingsState['borderStyle']>) => {
      state.borderStyle = action.payload;
    },
    setFontStyle: (state, action: PayloadAction<SettingsState['fontStyle']>) => {
      state.fontStyle = action.payload;
    },
    setHasCrossSells: (state, action: PayloadAction<SettingsState['hasCrossSells']>) => {
      state.hasCrossSells = action.payload;
    },
    setUseCustomDomain: (state, action: PayloadAction<SettingsState['useCustomDomain']>) => {
      state.useCustomDomain = action.payload;
    },
    setModeAndResetPreferences: (state, action: PayloadAction<SettingsState['mode']>) => {
      state.mode = action.payload;
      state.hasFreeTrial = false;
      state.billingPeriod = 'monthly';
      state.hasBillingAndShipping = false;
      state.hasPhoneNumber = false;
      state.hasShippingRate = false;
      state.hasTaxes = false;
      state.hasCoupons = false;
      state.hasPolicies = false;
      state.billingType = 'flat';
      state.hasUpsells = false;
      state.borderStyle = 'rounded';
      state.hasCrossSells = false;
      state.fontStyle = 'System';
    },
    setBillingTypeToMetered: (state) => {
      state.hasUpsells = false;
      state.billingType = 'metered';
    },
    setBillingPeriodToYearly: (state) => {
      state.hasUpsells = false;
      state.billingPeriod = 'yearly';
    },
    setBillingAndShippingAndTaxes: (state, action: PayloadAction<boolean>) => {
      state.hasBillingAndShipping = action.payload;
      state.hasTaxes = action.payload;
    },
    setBillingAndShippingAndRate: (state, action: PayloadAction<{
      hasBillingAndShipping: SettingsState['hasBillingAndShipping'],
      hasShippingRate: SettingsState['hasShippingRate']
    }>) => {
      const {hasBillingAndShipping, hasShippingRate} = action.payload;
      state.hasBillingAndShipping = hasBillingAndShipping;
      state.hasShippingRate = hasShippingRate;
    },
    postMessageUpdate: (state, action: PayloadAction<ParamsPayload>) => {
      const validatedPayload = getValidatedPayload(action.payload);
      Object.entries(validatedPayload).forEach(([key, value]) => {
        (state as Record<string, unknown>)[key] = value;
      });
    }
  },
})

export function getInitialState(): SettingsState {
  const result = {...defaultState};
  const overrides = mergeUrlConfigParams() as Partial<SettingsState>;

  for (const [key, value] of Object.entries(overrides)) {
    if (!!value) {
      (result as Record<string, unknown>)[key] = value;
    }
  }

  return result
}

export const { 
  setMode,
  setHasFreeTrial,
  setBillingPeriod,
  setBillingType,
  setHasUpsells,
  setHasBillingAndShipping,
  setHasTaxes,
  setHasPhoneNumber,
  setHasShippingRate,
  setHasCoupons,
  setCustomBrandColor,
  setSelectedBrandColorOption,
  setHasPolicies,
  setBorderStyle,
  setFontStyle,
  setHasCrossSells,
  setUseCustomDomain,
  setModeAndResetPreferences,
  setBillingTypeToMetered,
  setBillingPeriodToYearly,
  setBillingAndShippingAndTaxes,
  setBillingAndShippingAndRate,
  postMessageUpdate,
} = settingsSlice.actions;

export default settingsSlice.reducer;
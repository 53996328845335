import {ReactElement} from 'react';

import Dropdown from '@base/Dropdown/Dropdown';
import Country from '@shared-types/country';

type LocationOption = {
  label: string;
  value: Country;
  currency: string;
};

const LOCATIONS: LocationOption[] = [
  {label: 'Australia', value: 'au', currency: 'aud'},
  {label: 'China', value: 'cn', currency: 'cny'},
  {label: 'France', value: 'fr', currency: 'eur'},
  {label: 'Germany', value: 'de', currency: 'eur'},
  {label: 'Japan', value: 'jp', currency: 'jpy'},
  {label: 'Mexico', value: 'mx', currency: 'mxn'},
  {label: 'Netherlands', value: 'nl', currency: 'gbp'},
  {label: 'Poland', value: 'pl', currency: 'pln'},
  {label: 'United Kingdom', value: 'gb', currency: 'gbp'},
  {label: 'United States', value: 'us', currency: 'usd'},
];

const DEFAULT_LOCATION: LocationOption = {
  label: 'United States',
  value: 'us',
  currency: 'usd',
};

type Props = {
  selectedLocation: Country;
  onChange?: (value: Country) => void;
  label?: string;
};

function LocationDropdown({
  selectedLocation,
  onChange = () => {},
  label = 'Customer location',
}: Props): ReactElement {
  return (
    <Dropdown
      className="LocationDropdown"
      label={label}
      selectedValue={selectedLocation || DEFAULT_LOCATION.value}
      options={LOCATIONS}
      onChange={onChange}
      tooltipContent={
        "Checkout adapts to your customer's location and preferences to show the language and payment methods most likely to increase conversion."
      }
    />
  );
}

export default LocationDropdown;

import { useEffect, useState } from 'react';
import FakeChrome, { FakeChromeProps, FakeURLs } from './FakeChrome';
import FakeCheckout from './FakeCheckout/FakeCheckout';
import { useDispatch, useSelector } from '@store';
import { setUseCustomDomain } from '@slices/settingsSlice';
import { BusinessModelPreviews } from '@helpers/constants';
import { usePostMessages } from '@helpers/hooks'; 

interface Props {
    FakeChromeProps?: Partial<FakeChromeProps>;
}

function Demo({FakeChromeProps = {}}: Props) {
    usePostMessages();
    const dispatch = useDispatch();

    const {
        mode,
        hasFreeTrial,
        billingPeriod,
        billingType,
        hasUpsells,
        hasBillingAndShipping,
        hasTaxes,
        hasPhoneNumber,
        hasShippingRate,
        hasCoupons,
        hasPolicies,
        customBrandColor,
        selectedBrandColorOption,
        borderStyle,
        fontStyle,
        hasCrossSells,
        useCustomDomain,
    } = useSelector(state => state.settings);

    const [fakeUrl, setFakeUrl] = useState<FakeURLs>('checkout.stripe.com');

    const hasCustomBgColor = selectedBrandColorOption.isCustom;
    const bgColor = hasCustomBgColor ? customBrandColor : selectedBrandColorOption.topColor;

    useEffect(() => {
        if (useCustomDomain) {
            setFakeUrl(((BusinessModelPreviews)[mode]).checkoutUrl);
        } else {
            setFakeUrl('checkout.stripe.com');
        }
    }, [mode, useCustomDomain]);

    const handleUseCustomDomain = (value: boolean) => {
        dispatch(setUseCustomDomain(value));
    }
      
    return (
        <FakeChrome
            showTopButtons
            isDesktop
            smallHeight
            allowDomainCustomization
            useCustomDomain={useCustomDomain}
            url={fakeUrl}
            setUseCustomDomain={handleUseCustomDomain}
            {...FakeChromeProps}
        >
            <FakeCheckout
                bgColor={bgColor}
                hasCustomBgColor={hasCustomBgColor}
                billingPeriod={billingPeriod}
                hasBillingAndShipping={hasBillingAndShipping}
                hasCoupons={hasCoupons}
                hasFreeTrial={hasFreeTrial}
                hasShippingRate={hasShippingRate}
                hasTaxes={hasTaxes}
                hasPhoneNumber={hasPhoneNumber}
                hasPolicies={hasPolicies}
                key="fakeChrome"
                mode={mode}
                billingType={billingType}
                hasUpsells={hasUpsells}
                hasCrossSells={hasCrossSells}
                borderStyle={borderStyle}
                fontStyle={fontStyle}
            />
        </FakeChrome>
    );
}

export default Demo;
import {useHistory, useLocation} from 'react-router-dom';
import classnames from 'classnames';
import {IS_MOBILE} from '@helpers/browsers';
import ExternalLink from '@base/ExternalLink';
import Slider from '@base/Toggle/Slider';
import BrandColor, {
  BrandColorOption,
} from '@/client/components/base/Toggle/BrandColor';
import ButtonGroup from '@base/Toggle/ButtonGroup';
import Icon from '@base/Icon';
import NextStep from '@components/NextStep';
import BusinessModelButton from '@components/BusinessModelButton';
import Breadcrumb from '@components/Breadcrumb';
import ViewToggle from '@components/ViewToggle';
import LocationDropdown from '@components/LocationDropdown';
import WalletDropdown from '@components/WalletDropdown';
import getDemoUrl, {DemoUrlParams} from '@helpers/getDemoUrl';
import {FontStyles} from '@helpers/fontHelper';

import './Navbar.css';
import {
  BillingPeriods,
  BillingTypes,
  BorderStyles,
  ViewSizes,
  Wallets,
} from '@helpers/types';
import {ReactElement} from 'react';
import TextDropdown from './base/Dropdown/TextDropdown';
import { useDispatch, useSelector } from '@store';
import { billingPeriodOptions, billingTypeOptions, borderStyleOptions, fontStyleOptions } from '@helpers/constants';
import { 
  setBillingAndShippingAndRate,
  setBillingAndShippingAndTaxes,
  setBillingPeriod, 
  setBillingPeriodToYearly, 
  setBillingType, 
  setBillingTypeToMetered, 
  setBorderStyle, 
  setCustomBrandColor, 
  setFontStyle, 
  setHasBillingAndShipping, 
  setHasCoupons, 
  setHasCrossSells, 
  setHasFreeTrial, 
  setHasPhoneNumber, 
  setHasPolicies, 
  setHasTaxes, 
  setHasUpsells, 
  setMode, 
  setSelectedBrandColorOption, 
} from '../slices/settingsSlice';
import { setView, setLocation, setWallet, setLocationAndClearOverlay } from '@slices/interfaceSlice';
import { getDefaultBrandColorOptions } from '@helpers/colorHelper';
import Country from '@/types/country';

async function redirectToCheckout(paramConfig: DemoUrlParams, location = 'us') {
  const url = await getDemoUrl(paramConfig, location);
  window.location.assign(url);
}

const urlToStepConfig: Record<
  string,
  {step: number; nextStepPath: string | null}
> = {
  '/': {
    step: 1,
    nextStepPath: '/configure',
  },
  '/configure': {
    step: 2,
    nextStepPath: '/preview',
  },
  '/preview': {
    step: 3,
    nextStepPath: null,
  },
};

function Subheading({step}: {step: number}) {
  switch (step) {
    case 1:
      return (
        <div key="bc1" className="Navbar-Subheading">
          <Breadcrumb step={1}>Choose your use case</Breadcrumb>
        </div>
      );
    case 2:
      return (
        <div key="bc2" className="Navbar-Subheading">
          <Breadcrumb step={2} to="/">
            Customize
          </Breadcrumb>
        </div>
      );
    case 3:
      return (
        <div key="bc3" className="Navbar-Subheading">
          <Breadcrumb step={3} to="/configure">
            Try it out
          </Breadcrumb>
        </div>
      );
    default:
      return <></>;
  }
}

function Navbar(): ReactElement | null {
  const dispatch = useDispatch();
  const {
    view,
    location,
    wallet,
  } = useSelector(state => state.interface);
  const {
    mode,
    hasFreeTrial,
    billingPeriod,
    billingType,
    hasUpsells,
    hasBillingAndShipping,
    hasTaxes,
    hasPhoneNumber,
    hasShippingRate,
    hasCoupons,
    customBrandColor,
    selectedBrandColorOption,
    hasPolicies,
    borderStyle,
    fontStyle,
    hasCrossSells,
  } = useSelector(state => state.settings);

  const history = useHistory();
  const {pathname} = useLocation();

  if (pathname === '/success') {
    return null;
  }

  const stepConfig = urlToStepConfig[pathname];
  const currentStep = stepConfig.step;
  const nextStepPath = stepConfig.nextStepPath;

  const isSubscriptionMode = mode === 'subscription';
  const isPaymentMode = mode === 'payment';

  const navbarClasses = classnames('Navbar', {
    'Navbar--onStepThree': currentStep === 3,
  });

  const billingTypeDisabled = hasUpsells;
  const billingPeriodDisabled = hasUpsells;
  const upsellsDisabled =
    billingType === 'metered' || billingPeriod === 'yearly';

  const brandColorOptions = getDefaultBrandColorOptions(isSubscriptionMode, customBrandColor)

  const handleBillingTypeChange = (value: BillingTypes) => {
    if (value === 'metered') {
      // All recurring prices that aren’t metered are eligible to use
      // subscription upsells.
      return dispatch(setBillingTypeToMetered());
    }
    dispatch(setBillingType(value));
  };

  const handleBillingPeriodChange = (value: BillingPeriods) => {
    if (value === 'yearly') {
      // Stripe doesn't support subscription intervals > 1 year so disable
      // upsells when the merchant selects the yearly billing period
      return dispatch(setBillingPeriodToYearly());
    }
    dispatch(setBillingPeriod(value));
  };

  const handleBorderStyleChange = (value: BorderStyles) => {
    dispatch(setBorderStyle(value));
  };

  const handleFontStyleChange = (value: FontStyles) => {
    dispatch(setFontStyle(value));
  };

  const handleBrandColorChange = (value: string) => {
    dispatch(setCustomBrandColor(value))
  }

  const handleBGColorChange = (value: BrandColorOption) => {
    dispatch(setSelectedBrandColorOption(value))
  }

  const handleAddressCollection = (value: boolean) => {
    if (!value) {
      return dispatch(setBillingAndShippingAndRate({
        hasBillingAndShipping: value,
        hasShippingRate: false,
      }));
    } else if (mode === 'payment') {
      return dispatch(setBillingAndShippingAndRate({
        hasBillingAndShipping: value,
        hasShippingRate: true,
      }));
    }

    dispatch(setHasBillingAndShipping(value));
  };

  const handleTaxesCollection = (value: boolean) => {
    if (mode === 'subscription') {
      return dispatch(setBillingAndShippingAndTaxes(value));
    }
    dispatch(setHasTaxes(value));
  };

  const handlePolicies = (value: boolean) => {
    dispatch(setHasPolicies(value));
  };

  const handleFreeTrial = (value: boolean) => {
    dispatch(setHasFreeTrial(value));
  };

  const handleCrossSells = (value: boolean) => {
    dispatch(setHasCrossSells(value));
  };

  const handleCoupons = (value: boolean) => {
    dispatch(setHasCoupons(value));
  };

  const handleUpSells = (value: boolean) => {
    dispatch(setHasUpsells(value));
  };

  const handlePhoneNumber = (value: boolean) => {
    dispatch(setHasPhoneNumber(value));
  };

  const handleLocation = (value: Country) => {
    dispatch(setLocation(value));
  };

  const handleView = (value: ViewSizes) => {
    dispatch(setView(value));
  };

  const handleWallet = (value: Wallets) => {
    dispatch(setWallet(value));
  };

  const handleLocationDropdownChange = (value: Country) => {
    // clear any overlays before refreshing iframe
    dispatch(setLocationAndClearOverlay(value));
  };

  const upsellsDisabledTooltip = () => {
    if (billingType === 'metered') {
      return 'Metered billing is not supported with upsells at this time.';
    }

    if (billingPeriod === 'yearly') {
      return 'Upsells longer than a year are not supported at this time.';
    }

    return '';
  };

  return (
    <div className={navbarClasses}>
      <header>
        <a href="https://stripe.com/payments/checkout">
          <Icon name="stripe" />
        </a>
        {!IS_MOBILE && (
          <div className="Navbar-Links">
            <ExternalLink
              href="https://dashboard.stripe.com/register"
              includeIcon={false}
            >
              Create account
            </ExternalLink>
            <div className="Navbar-Spacer" />
            <ExternalLink href="https://stripe.com/docs/checkout/quickstart">
              Explore the docs
            </ExternalLink>
          </div>
        )}
      </header>
      <div className="Navbar-Content">
        <div className="Navbar-ColumnLeft">
          <div className="Navbar-Title">
            <h1>Explore Checkout</h1>
            <Subheading step={currentStep} />
          </div>
        </div>

        {currentStep === 1 && (
          <div className="Navbar-ColumnRight">
            <div className="Navbar-StepOne">
              <BusinessModelButton
                type="payment"
                selected={mode === 'payment'}
                onClick={() => dispatch(setMode('payment'))}
              />
              <BusinessModelButton
                type="subscription"
                selected={mode === 'subscription'}
                onClick={() => dispatch(setMode('subscription'))}
              />
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="Navbar-ColumnRight">
            {/* Each row should have at most 3 toggles */}
            <div className={`Navbar-StepTwo Navbar-StepTwo--${mode}`}>
              {/* Subscription mode toggle order */}
              {isSubscriptionMode && (
                <>
                  <BrandColor
                    selectedKey={selectedBrandColorOption.key}
                    setCustomBrandColor={handleBrandColorChange}
                    onChange={handleBGColorChange}
                    label="Brand colors"
                    options={brandColorOptions}
                    tooltipContent="Customize Checkout's colors to match your brand."
                  />
                  <ButtonGroup
                    className="Toggle--billingType"
                    onChange={handleBillingTypeChange}
                    selected={billingType}
                    disabled={billingTypeDisabled}
                    disabledTooltipContent="Metered billing is not supported with upsells at this time."
                    label="Subscription"
                    tooltipContent="Checkout supports usage-based and tiered subscriptions."
                    options={billingTypeOptions}
                  />
                  <ButtonGroup
                    className="Toggle--billingPeriod"
                    onChange={handleBillingPeriodChange}
                    selected={billingPeriod}
                    disabled={billingPeriodDisabled}
                    disabledTooltipContent="Upsells longer than a year are not supported at this time."
                    label="Billing period"
                    tooltipContent="Change the billing cycle for your subscription."
                    options={billingPeriodOptions}
                  />
                  <TextDropdown
                    className="Toggle--fontStyle"
                    selectedValue={fontStyle}
                    options={fontStyleOptions}
                    onClick={handleFontStyleChange}
                    type="textDropdown"
                    label="Font"
                    tooltipContent=""
                  />
                  <TextDropdown
                    className="Toggle--borderStyle"
                    selectedValue={borderStyle}
                    options={borderStyleOptions}
                    onClick={handleBorderStyleChange}
                    type="textDropdown"
                    label="Style"
                    tooltipContent=""
                  />
                  <Slider
                    className="Toggle--policies"
                    onChange={handlePolicies}
                    selected={hasPolicies}
                    label="Store policies"
                    tooltipContent="Highlight your return policies, support information, or terms of service."
                  />
                  <Slider
                    className="Toggle--freeTrial"
                    onChange={handleFreeTrial}
                    selected={hasFreeTrial}
                    label={'Free trial'}
                    tooltipContent="Let your customers try your product or service for free."
                  />
                  <Slider
                    className="Toggle--crossSells"
                    onChange={handleCrossSells}
                    selected={hasCrossSells}
                    label="Suggest product"
                    tooltipContent={
                      'Allow customers to purchase complementary products during checkout.'
                    }
                  />
                  <Slider
                    className="Toggle--taxes"
                    onChange={handleTaxesCollection}
                    selected={hasTaxes}
                    label="Tax support"
                    tooltipContent="Set tax rates by region to collect the right amount based on your customer's location."
                  />
                  <Slider
                    className="Toggle--coupons"
                    onChange={handleCoupons}
                    selected={hasCoupons}
                    label="Coupons"
                    tooltipContent="Create discount and promo codes to reward your most loyal customers."
                  />
                  <Slider
                    className="Toggle--upsells"
                    onChange={handleUpSells}
                    selected={hasUpsells}
                    disabled={upsellsDisabled}
                    disabledTooltipContent={upsellsDisabledTooltip()}
                    label="Upsells"
                    tooltipContent="Allow customers to upgrade their order during checkout."
                  />
                  <Slider
                    className="Toggle--phoneNumber"
                    onChange={handlePhoneNumber}
                    selected={hasPhoneNumber}
                    label="Phone number"
                    tooltipContent={
                      'Collect your customer’s phone number if you need to contact them regarding the transaction.'
                    }
                  />
                  {/* Used to align the last toggle to be in the middle row */}
                </>
              )}

              {/* Payment mode toggle order */}
              {isPaymentMode && (
                <>
                  <BrandColor
                    type="brandColor"
                    selectedKey={selectedBrandColorOption.key}
                    setCustomBrandColor={handleBrandColorChange}
                    onChange={handleBGColorChange}
                    label="Brand colors"
                    options={brandColorOptions}
                    tooltipContent="Customize Checkout's colors to match your brand."
                  />
                  <Slider
                    className="Toggle--coupons"
                    onChange={handleCoupons}
                    selected={hasCoupons}
                    label="Coupons"
                    tooltipContent="Create discount and promo codes to reward your most loyal customers."
                  />
                  <Slider
                    className="Toggle--address"
                    onChange={handleAddressCollection}
                    selected={hasBillingAndShipping}
                    label="Shipping"
                    tooltipContent={
                      'Collect your customer’s shipping information and shipping method preference during checkout.'
                    }
                  />
                  <Slider
                    className="Toggle--policies"
                    onChange={handlePolicies}
                    selected={hasPolicies}
                    label="Store policies"
                    tooltipContent="Highlight your return policies, support information, or terms of service."
                  />
                  <Slider
                    className="Toggle--taxes"
                    onChange={handleTaxesCollection}
                    selected={hasTaxes}
                    label="Tax support"
                    tooltipContent="Set tax rates by region to collect the right amount based on your customer's location."
                  />
                  <Slider
                    className="Toggle--crossSells"
                    onChange={handleCrossSells}
                    selected={hasCrossSells}
                    label="Suggest product"
                    tooltipContent={
                      'Allow customers to purchase complementary products during checkout.'
                    }
                  />
                  <Slider
                    className="Toggle--phoneNumber"
                    onChange={handlePhoneNumber}
                    selected={hasPhoneNumber}
                    label="Phone number"
                    tooltipContent="Collect your customer’s phone number if you need to contact them regarding the transaction."
                  />
                  <TextDropdown
                    className="Toggle--borderStyle"
                    selectedValue={borderStyle}
                    options={borderStyleOptions}
                    onClick={handleBorderStyleChange}
                    type="textDropdown"
                    label="Style"
                    tooltipContent=""
                  />
                  {/* Used to align the last toggle to be in the middle row */}
                  {/* <div className="Toggle--placeholder" /> */}
                  <TextDropdown
                    className="Toggle--fontStyle"
                    selectedValue={fontStyle}
                    options={fontStyleOptions}
                    onClick={handleFontStyleChange}
                    type="textDropdown"
                    label="Font"
                    tooltipContent=""
                  />
                </>
              )}
              {IS_MOBILE && (
                <LocationDropdown
                  selectedLocation={location}
                  onChange={handleLocation}
                  label="Location"
                />
              )}
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div className="Navbar-ColumnRight">
            <div className="Navbar-StepThree">
              <div className="Navbar-StepThreeActions">
                <ViewToggle selectedView={view} onClick={handleView} />
                <WalletDropdown selectedWallet={wallet} onChange={handleWallet} />
                <LocationDropdown
                  selectedLocation={location}
                  onChange={handleLocationDropdownChange}
                />
              </div>
            </div>
          </div>
        )}

        {currentStep !== 3 && (
          <NextStep
            to={
              !(IS_MOBILE && currentStep === 2) && nextStepPath
                ? nextStepPath
                : ''
            }
            icon="chevronRightWhite"
            onClick={() => {
              if (IS_MOBILE && currentStep === 2) {
                redirectToCheckout(
                  {
                    mode,
                    wallet,
                    hasCoupons,
                    hasTaxes,
                    hasBillingAndShipping,
                    hasShippingRate,
                    hasFreeTrial,
                    billingPeriod,
                    hasPolicies,
                    hasPhoneNumber,
                    hasUpsells,
                  },
                  location
                );
              } else {
                history.push(nextStepPath || '');
              }
            }}
          >
            Next
          </NextStep>
        )}
      </div>
    </div>
  );
}

export default Navbar;

import { ReactElement } from 'react';
import Toggle, { ToggleProps } from '../Toggle/Toggle';
import DropdownSelect, { DropdownOption } from './DropdownSelect';

import '../Toggle/Toggle.css';

type TextDropdownProps<T> = ToggleProps & {
  selectedValue: T;
  options: DropdownOption<T>[];
  onClick: (val: T) => void;
};

function TextDropdown<T extends string>(props: TextDropdownProps<T>): ReactElement {

  const {
    selectedValue,
    onClick,
    options,
    disabled,
    label,
    tooltipContent,
    className,
    tooltipDistance,
    disabledTooltipContent,
  } = props

  return (
    <Toggle
      type='textDropdown'
      label={label}
      tooltipContent={tooltipContent}
      className={className}
      tooltipDistance={tooltipDistance}
      disabledTooltipContent={disabledTooltipContent}
      disabled={disabled}
    >     
    <div className={"TextDropdown"}>
                  
        <DropdownSelect
            selectedValue={selectedValue}
            options={options}
            onClick={onClick}
            
        ></DropdownSelect>
    </div>
    </Toggle>
  );
}

export default TextDropdown;

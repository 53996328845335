import { ReactElement } from 'react';

import classNames from 'classnames';
import Icon from '@base/Icon';
import { ViewSizes } from '@helpers/types';

import './ViewToggle.css';

type Props = {
  selectedView: ViewSizes;
  onClick: (size: ViewSizes) => void;
};

function ViewToggle({selectedView, onClick}: Props): ReactElement {
  const desktopClassNames = classNames('ViewToggle-Icon', {
    'ViewToggle-Icon--selected': selectedView === 'desktop',
  });

  const mobileClassNames = classNames('ViewToggle-Icon', {
    'ViewToggle-Icon--selected': selectedView === 'mobile',
  });

  return (
    <div className="ViewToggle">
      <button onClick={() => onClick('desktop')}>
        <Icon className={desktopClassNames} name="desktop" />
      </button>
      <button style={{marginLeft: -2}} onClick={() => onClick('mobile')}>
        <Icon className={mobileClassNames} name="mobile" />
      </button>
    </div>
  );
}

export default ViewToggle;

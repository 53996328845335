import { ReactElement, CSSProperties } from 'react';

import classnames from 'classnames';
import {motion} from 'framer-motion';
import './Placeholder.css';
import { BorderStyles } from '@helpers/types';

type Props = {
  height?: number | string;
  width?: number | string;
  light?: boolean;
  fakeInput?: boolean;
  fullWidth?: boolean;
  style?: CSSProperties;
  animateAsPartOfLayout?: boolean;
  borderStyle?: BorderStyles | string; 
};

function Placeholder({
  height = 8,
  width,
  light = false,
  fakeInput = false,
  fullWidth = false,
  style = {},
  // Makes the placeholder a motion.div if
  // part of a layout shift. This smoothens
  // any position transitions.
  animateAsPartOfLayout = false,
  borderStyle = 'rounded'
}: Props): ReactElement {

  const getPlaceholderBorderStyleClass = () => {
    if (borderStyle === 'sharp') {
      return 'Placeholder--Sharp';
    } else if (borderStyle === 'pill') {
      return 'Placeholder--Pill';
    } else {
      return 'Placeholder--Rounded';
    }
  }

  const placeholderClassNames = classnames('Placeholder', {
    'Placeholder--light': light,
    'Placeholder--fakeInput': fakeInput,
    'Placeholder--fullWidth': fullWidth || !width,
  }, getPlaceholderBorderStyleClass());

  return animateAsPartOfLayout ? (
    <motion.div
      layout
      className={placeholderClassNames}
      style={{width, height, ...style}}
    />
  ) : (
    <div className={placeholderClassNames} style={{width, height, ...style}} />
  );
}

export default Placeholder;

import {ReactComponent as au} from '@icons/flags/au.svg';
import {ReactComponent as br} from '@icons/flags/br.svg';
import {ReactComponent as cn} from '@icons/flags/cn.svg';
import {ReactComponent as de} from '@icons/flags/de.svg';
import {ReactComponent as fr} from '@icons/flags/fr.svg';
import {ReactComponent as gb} from '@icons/flags/gb.svg';
import {ReactComponent as jp} from '@icons/flags/jp.svg';
import {ReactComponent as mx} from '@icons/flags/mx.svg';
import {ReactComponent as nl} from '@icons/flags/nl.svg';
import {ReactComponent as pl} from '@icons/flags/pl.svg';
import {ReactComponent as us} from '@icons/flags/us.svg';

const flags = {
  au,
  br,
  cn,
  de,
  fr,
  gb,
  jp,
  mx,
  nl,
  pl,
  us,
};

export type Flags = keyof typeof flags;

export default flags;

import { useState, useEffect, ReactElement } from 'react';
import {Transition} from 'react-transition-group';
import Column from '@base/Column';

import './StepTwo.css';
import {useDispatch, useSelector} from '@store';
import Demo from '@components/Demo';
import { setStepTwoRenderSettings } from '@slices/interfaceSlice';

function StepTwo(): ReactElement {
  const dispatch = useDispatch();
  const animateFromStepThree = useSelector(state => state.interface.animateFromStepThree);
  const [animate, setAnimate] = useState(!animateFromStepThree);

  useEffect(() => {
    dispatch(setStepTwoRenderSettings());
    setTimeout(() => setAnimate(true), 10);
  });

  return (
    <Column className="StepTwo-Preview">
      <Transition in={animate} timeout={300}>
        {state => (
          <Demo 
            FakeChromeProps={{
              className: `FakeChrome-AnimateStep2--${state}`
            }}
          />
        )}
      </Transition>
    </Column>
  );
}

export default StepTwo;

import { 
    postMessageUpdate
} from "@slices/settingsSlice";
import { useDispatch } from "@store";
import { useEffect } from "react";

export function usePostMessages() {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            dispatch(postMessageUpdate(event.data));
        };
    
        window.addEventListener('message', handleMessage);
    
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [dispatch]);
}

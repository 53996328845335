import { ReactElement, ReactNode, MouseEventHandler, CSSProperties } from 'react';
import Icon, { IconsKey } from '@base/Icon';
import classnames from 'classnames';

import './IconButton.css';

/* Keep in sync with supported CSS rotations that have been implemented */
export type IconAnimations = '' | 'rotate';

type Props = {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
  icon?: IconsKey;
  primary?: boolean;
  secondary?: boolean;
  animationForIcon?: IconAnimations;
};

function IconButton({
  onClick,
  children,
  style = {},
  icon = 'plainCheck',
  primary = false,
  secondary = false,
  animationForIcon = '',
}: Props): ReactElement {
  const classes = classnames('IconButton', {
    'IconButton--primary': primary,
    'IconButton--secondary': secondary,
  });

  const iconClassNames = classnames('IconButton-Icon', {
    'IconButton-Icon--rotateAnimation': animationForIcon === 'rotate',
  });

  return (
    <button className={classes} onClick={onClick} style={{...style}}>
      <span className="IconButton-Text">{children}</span>
      <Icon className={iconClassNames} name={icon} />
    </button>
  );
}

export default IconButton;

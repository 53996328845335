import {useState, useEffect, ReactElement} from 'react';
import {Transition} from 'react-transition-group';

import FakeChrome, {FakeURLs} from '@components/FakeChrome';
import Checkout from '@components/Checkout';
import CheckoutOverlay, {OverlayOptions} from '@components/CheckoutOverlay';
import getDemoUrl from '@helpers/getDemoUrl';
import TestInputs from '@components/TestInputs';
import classNames from 'classnames';

import './StepThree.css';
import { useDispatch, useSelector } from '@store';
import { setAnimateFromStepThree, setFocusedInput, setOverlay } from '@slices/interfaceSlice';
import { setUseCustomDomain } from '@slices/settingsSlice';
import { BusinessModelPreviews } from '@helpers/constants';
import { isLightHex } from '@helpers/colorHelper';

function StepThree(): ReactElement {
  const dispatch = useDispatch();
  const {
    view,
    focusedInput,
    overlay,
    location,
    wallet,
  } = useSelector(state => state.interface);
  const {
    mode,
    hasFreeTrial,
    billingPeriod,
    billingType,
    hasUpsells,
    hasBillingAndShipping,
    hasTaxes,
    hasPhoneNumber,
    hasShippingRate,
    hasCoupons,
    hasPolicies,
    customBrandColor,
    selectedBrandColorOption,
    borderStyle,
    fontStyle,
    hasCrossSells,
    useCustomDomain,
  } = useSelector(state => state.settings)

  const [url, setCheckoutURL] = useState('');
  const [refresh, toggleRefresh] = useState(true);
  const refreshIframe = () => toggleRefresh(!refresh);
  const [animate, setAnimate] = useState(false);

  const hasCustomBgColor = selectedBrandColorOption.isCustom;
  const bgColor = hasCustomBgColor ? customBrandColor : selectedBrandColorOption.topColor;
  const buttonColor = hasCustomBgColor ? customBrandColor : selectedBrandColorOption.bottomColor;
  const hasBgColor = isLightHex(bgColor);
  
  useEffect(() => {
    dispatch(setAnimateFromStepThree(view === 'desktop'));
    setTimeout(() => setAnimate(true), 50);
  });

  useEffect(() => {
    async function fetchCheckoutSession() {
      // country=location param is already part of paramString build
      // so don't include in paramConfig
      const paramConfig = {
        billingPeriod,
        hasBgColor,
        bgColor,
        buttonColor,
        hasBillingAndShipping,
        hasCoupons,
        hasFreeTrial,
        hasShippingRate,
        hasTaxes,
        mode,
        wallet,
        hasPolicies,
        billingType,
        hasUpsells,
        hasPhoneNumber,
        borderStyle,
        fontStyle,
        hasCrossSells
      };

      const demoUrl = await getDemoUrl(paramConfig, location);
      setCheckoutURL(demoUrl);
    }

    setCheckoutURL('about:blank');
    fetchCheckoutSession();

    // We save wallet as a param to rerender iframe with user's selected
    // wallet if they refresh the page. It should not refresh the iframe on change
    // since wallet selection is updated with postMessage.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billingPeriod,
    hasBgColor,
    bgColor,
    buttonColor,
    hasBillingAndShipping,
    hasCoupons,
    hasFreeTrial,
    hasShippingRate,
    hasTaxes,
    hasPhoneNumber,
    hasUpsells,
    location,
    mode,
    borderStyle,
    fontStyle,
    hasCrossSells,
    refresh, // used as toggle between T/F to refresh
  ]);

  useEffect(() => {
    // TODO: It is less than ideal to rely on document searches like below,
    // we should be able to make a reference (React.useRef) to what we need.
    const el = document.getElementById('checkout-demo');
    if (!!el) {
      const frame = el as HTMLIFrameElement;
      frame.contentWindow?.postMessage(
        {
          type: 'demoSetWallet',
          payload: wallet,
        },
        '*',
      );
    }
    // only fetch if wallet changes
  }, [wallet]);

  useEffect(() => {
    function handleMessage(event: MessageEvent<Record<string, string>>) {
      switch (event.data.event) {
        case 'submitPayment':
          if (event.data.result === 'success') {
            dispatch(setOverlay('success'));
          }
          break;
        case 'submitBegin':
          if (event.data.paymentMethod !== 'card') {
            dispatch(setOverlay(event.data.paymentMethod as OverlayOptions));
          }
          break;
        case 'walletClicked':
          dispatch(setOverlay(event.data.wallet as OverlayOptions));
          break;
        case 'inputFocused':
          dispatch(setFocusedInput(event.data.input));
          break;
        case 'changePaymentMethod':
          // If user changes selected payment method on Checkout
          // and the demo has already shown the input card/coupon entries etc
          // then update to show the newly selected payment method's
          // applicable input entries. Some payment method's don't have
          // example input entries in which case it will hide the component.
          if (focusedInput !== '') {
            dispatch(setFocusedInput(event.data.paymentMethod));
          }
          break;
        default:
          break;
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  const isMobileView = view === 'mobile';
  const showOverlay = !!overlay;
  const testInputCssClasses = classNames(['StepThree-TestInputs']);

  const [fakeUrl, setFakeUrl] = useState<FakeURLs>('checkout.stripe.com');
  useEffect(() => {
    if (useCustomDomain) {
      setFakeUrl(BusinessModelPreviews[mode].checkoutUrl);
    } else {
      setFakeUrl('checkout.stripe.com');
    }
  }, [mode, useCustomDomain]);

  const handleCustomDomain = (value: boolean) => {
    dispatch(setUseCustomDomain(value));
  };

  return (
    <div className="StepThree">
      <Transition in={animate} timeout={300}>
        {state => (
          <FakeChrome
            key="fakeChrome"
            isMobile={isMobileView}
            isDesktop={view === 'desktop'}
            className={`FakeChrome-AnimateStep3--${state}`}
            allowDomainCustomization
            useCustomDomain={useCustomDomain}
            setUseCustomDomain={handleCustomDomain}
            url={fakeUrl}
          >
            <Checkout url={url} blur={showOverlay} />
            {showOverlay && (
              <CheckoutOverlay
                isMobileView={isMobileView}
                overlay={overlay}
                onSuccess={() => {
                  refreshIframe();
                  dispatch(setOverlay(null));
                }}
              />
            )}
          </FakeChrome>
        )}
      </Transition>
      <div className={testInputCssClasses}>
        {hasCoupons && (
          <TestInputs input="coupon" focusedInput={focusedInput} minimize={showOverlay}/>
        )}
        <TestInputs input="card" focusedInput={focusedInput} minimize={showOverlay}/>
      </div>
    </div>
  );
}

export default StepThree;

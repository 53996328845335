import {Link} from 'react-router-dom';
import { ReactElement, ReactNode } from 'react';
import Icon from '@base/Icon';

import './Breadcrumb.css';

type Props = {
  step: number;
  children: ReactNode;
  to?: string;
};

function Breadcrumb({
  to,
  step,
  children,
}: Props): ReactElement {
  if (!to) {
    // If no "to" is provided, render with no arrow or link
    return (
      <div className="Breadcrumb Breadcrumb--noLink">
        <span className="Breadcrumb-Link">{children}</span>
        <span className="Breadcrumb-Step">{step} of 3</span>
      </div>
    );
  }

  return (
    <div className="Breadcrumb">
      <div className="Breadcrumb-LinkAnimator">
        <Link to={to} className="Breadcrumb-Link">
          <Icon className="Breadcrumb-Arrow" name="arrowLeft" />
          <span className="Breadcrumb-Content">{children}</span>
          <span className="Breadcrumb-Back">Back</span>
        </Link>
        <span className="Breadcrumb-Step">{step} of 3</span>
      </div>
    </div>
  );
}

export default Breadcrumb;
